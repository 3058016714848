import React, { useCallback } from 'react'
import PT from 'prop-types'
import { Input } from 'antd'
import debounce from 'lodash.debounce'

const CodeEditor = ({ message, onMessageChange, loading }) => {
  const [textInput, inputChange] = React.useState(JSON.stringify(message.attachment))
  React.useEffect(() => {
    inputChange(JSON.stringify(message.attachment))
  }, [message])

  const delayedUpdateAction = useCallback(
    debounce((attachment) => {
      onMessageChange({
        attachment,
      })
    }, 1000),
    []
  )

  const handleTextChange = () => {
    let newMsg = {
      attachment: message.attachment,
    }
    if (textInput === '') {
      newMsg.attachment = JSON.stringify(message.attachment)
      inputChange(JSON.stringify(message.attachment))
    }
    onMessageChange(newMsg)
  }
  return (
    <div>
      <Input.TextArea
        style={{ width: '100%', resize: 'none', border: 'none' }}
        autoSize={{ minRows: 10 }}
        value={textInput}
        onChange={(e) => {
          inputChange(e.target.value)
          delayedUpdateAction(e.target.value || message.attachment)
        }}
        onBlur={handleTextChange}
        disabled={loading}
      />
    </div>
  )
}

CodeEditor.propTypes = {
  message: PT.object,
  onMessageChange: PT.func,
  loading: PT.bool,
}

CodeEditor.defaultProps = {
  message: {},
  onMessageChange: () => {},
}

export default CodeEditor
