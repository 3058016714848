import React from 'react'
import MessengerCustomerChat from 'react-messenger-customer-chat'

export const MessengerChat = () => {
  const msg_th = 'สอบถามข้อมูลเพิ่มเติม ยินดีให้บริการค่ะ'

  return (
    <MessengerCustomerChat
      pageId="813956442139562"
      appId="879998535846224"
      shouldShowDialog={false}
      loggedInGreeting={msg_th}
      loggedOutGreeting={msg_th}
      version="8.0"
      themeColor="#a695c7"
    />
  )
}

export default MessengerChat
