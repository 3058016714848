export const getErrorMessage = (err) => {
  var message = ''
  if (Array.isArray(err.graphQLErrors) && err.graphQLErrors.length !== 0) {
    err.graphQLErrors.forEach(function (graphQLError) {
      message += graphQLError.message + '\n'
    })
  }
  if (err.networkError) {
    message += 'Network error: ' + err.networkError.message + '\n'
  }
  message = message.replace(/\n$/, '')
  return message
}
