import { NO_IMG, DEFAULT_IMGMAP } from './defaultValue'

export const defaultLineMsg = {
  text: {
    type: 'text',
    text: 'Text Message',
  },
  image: {
    type: 'image',
    originalContentUrl: NO_IMG,
    previewImageUrl: NO_IMG,
  },
  imagemap: {
    type: 'imagemap',
    baseUrl: DEFAULT_IMGMAP + '?=ignore',
    altText: 'This is an imagemap',
    baseSize: {
      width: 1040,
      height: 1040,
    },
    actions: [
      {
        type: 'message',
        text: 'Imagemap Clicked',
        area: {
          x: 0,
          y: 0,
          width: 1040,
          height: 1040,
        },
      },
    ],
  },
  flex: {
    type: 'flex',
    altText: 'Flex Message',
    contents: {
      type: 'bubble',
      body: {
        type: 'box',
        layout: 'vertical',
        contents: [
          {
            type: 'text',
            text: 'hello',
          },
          {
            type: 'text',
            text: 'world',
          },
        ],
      },
    },
  },
  dynamicFlex: {
    type: 'flex',
    altText: 'Dynamic Flex',
    contents: {
      type: 'carousel',
      contents: [
        {
          type: 'bubble',
          body: {
            type: 'box',
            layout: 'vertical',
            contents: [
              {
                type: 'text',
                text: 'First bubble',
              },
            ],
          },
        },
      ],
    },
  },
  googleSheetFlex: {
    type: 'flex',
    altText: 'Google Sheet Flex',
    contents: {
      type: 'carousel',
      contents: [
        {
          type: 'bubble',
          body: {
            type: 'box',
            layout: 'vertical',
            contents: [
              {
                type: 'text',
                text: 'First bubble',
              },
            ],
          },
        },
      ],
    },
  },
  productFlex: {
    type: 'flex',
    altText: 'Product Flex',
    contents: {
      type: 'carousel',
      contents: [
        {
          type: 'bubble',
          body: {
            type: 'box',
            layout: 'vertical',
            contents: [
              {
                type: 'text',
                text: 'First bubble',
              },
            ],
          },
        },
      ],
    },
  },
  placeFlex: {
    type: 'flex',
    altText: 'Place Flex',
    contents: {
      type: 'carousel',
      contents: [
        {
          type: 'bubble',
          body: {
            type: 'box',
            layout: 'vertical',
            contents: [
              {
                type: 'text',
                text: 'First bubble',
              },
            ],
          },
        },
      ],
    },
  },
  bookingFlex: {
    type: 'flex',
    altText: 'Booking Flex',
    contents: {
      type: 'carousel',
      contents: [
        {
          type: 'bubble',
          body: {
            type: 'box',
            layout: 'vertical',
            contents: [
              {
                type: 'text',
                text: 'First bubble',
              },
            ],
          },
        },
      ],
    },
  },
}

export const defaultFacebookMsg = {
  text: {
    text: 'Text Message',
  },
  image: {
    attachment: {
      type: 'image',
      payload: {
        url: NO_IMG,
      },
    },
  },
  carousel: {
    attachment: {
      type: 'template',
      payload: {
        template_type: 'generic',
        elements: [
          {
            title: 'Title',
            image_url: undefined,
            subtitle: 'Subtitle',
            buttons: [
              {
                type: 'postback',
                title: 'Action',
                payload: 'Action',
              },
            ],
          },
        ],
      },
    },
  },
  dynamicCarousel: {
    attachment: {
      type: 'template',
      payload: {
        template_type: 'generic',
        elements: [
          {
            title: 'Title',
            image_url: undefined,
            subtitle: 'Subtitle',
            buttons: [
              {
                type: 'postback',
                title: 'Action',
                payload: 'Action',
              },
            ],
          },
        ],
      },
    },
  },
  button: {
    attachment: {
      type: 'template',
      payload: {
        template_type: 'button',
        text: 'กรุณาเลือก',
        buttons: [
          {
            type: 'postback',
            title: 'promotion',
            payload: `promotion`,
          },
          {
            type: 'postback',
            title: 'review',
            payload: `review`,
          },
        ],
      },
    },
  },
}

export const defaultLineQR = {
  message: {
    type: 'action',
    action: {
      type: 'message',
      label: 'Message Action',
      text: 'Message',
    },
  },
  postback: {
    type: 'action',
    action: {
      type: 'postback',
      label: 'Postback Action',
      data: 'GREETING',
      displayText: 'สวัสดี',
    },
  },
  location: {
    type: 'action',
    action: {
      type: 'location',
      label: 'Location Action',
    },
  },
}

export const defaultFacebookQR = {
  text: {
    content_type: 'text',
    title: 'Message Action',
    payload: 'Message Payload',
  },
}
