import React from 'react'
import PT from 'prop-types'
import { CloseOutlined } from '@ant-design/icons'

function animateCSS(element, animationName, callback) {
  const node = document.querySelector(element)
  node.classList.add('animated', animationName)

  function handleAnimationEnd() {
    node.classList.remove('animated', animationName)
    node.removeEventListener('animationend', handleAnimationEnd)

    if (typeof callback === 'function') callback()
  }

  node.addEventListener('animationend', handleAnimationEnd)
}

type Props = {
  children: any
  visible: boolean
  onClose: () => void
  style?: any
  disabledDimmerClick?: boolean
  showCloseIcon?: boolean
  fullScreen?: boolean
}

export const Modal = ({
  style,
  visible,
  onClose,
  children,
  disabledDimmerClick,
  showCloseIcon,
  fullScreen,
}: Props) => {
  const handleDimmerClick = () => {
    // animateCSS('#container', 'zoomOut')
    // animateCSS('#dimmer', 'fadeOut', () => {
    //   onClose()
    // })
    onClose()
  }

  const windowStyle = {
    width: 700,
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 4,
    backgroundColor: 'white',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
    padding: 16,
    ...style,
  }
  const fullScreenStyle = {
    backgroundColor: 'white',
    padding: 16,
    width: '100%',
    height: '100%',
    ...style,
  }

  if (!visible || !children) {
    return null
  }
  return (
    <div
      id="dimmer"
      style={{
        zIndex: 999,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
      }}
      className={'animated fadeIn faster'}
    >
      <div
        id="container"
        style={fullScreen ? fullScreenStyle : windowStyle}
        className={'animated zoomIn faster'}
      >
        {showCloseIcon && (
          <div
            style={{ position: 'absolute', top: 16, right: 16, cursor: 'pointer' }}
            onClick={handleDimmerClick}
          >
            <CloseOutlined style={{ fontSize: '1.2em', color: 'rgba(0, 0, 0, 0.15)' }} />
          </div>
        )}
        {children}
      </div>
      <div
        onClick={!disabledDimmerClick ? handleDimmerClick : () => {}}
        style={{ width: '100%', height: '100%' }}
      ></div>
    </div>
  )
}

// Modal.propTypes = {
//   children: PT.oneOfType([PT.node, PT.string]),
//   visible: PT.bool,
//   onClose: PT.func,
//   style: PT.object,
//   disabledDimmerClick: PT.bool,
//   showCloseIcon: PT.bool,
//   fullScreen: PT.bool,
// }

// Modal.defaultProps = {
//   onClose: () => {},
// }
