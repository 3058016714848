import React from 'react'
import PT from 'prop-types'
import { Input, message as AntMessage } from 'antd'

import ActionsEditor from './ActionsEditor'
import ImageInput from '../../../components/ImageInput'
import { getImageResolution } from '../../../helpers/file'
import { InfoCircleOutlined } from '@ant-design/icons'

const ImagemapEditor = ({ botId, message, data, onMessageChange, loading }) => {
  const [isReady, setReady] = React.useState(false)
  const [inputText, setInput] = React.useState(message.altText)
  const [displayImg, setDisplayImg] = React.useState(data.imageUrl || message.baseUrl)

  const mapDataToNewImagemap = async () => {
    let newData = { ...data }
    let isTouched = false
    if (!data.imageUrl) {
      newData = {
        ...newData,
        imageUrl: message.baseUrl,
      }
      isTouched = true
    }
    if (!data.grid) {
      newData = {
        ...newData,
        grid: {
          w: (data.template || {}).row,
          h: (data.template || {}).col,
        },
      }
      isTouched = true
    }
    if (!data.baseSize) {
      const size = await getImageResolution(message.baseUrl)
      newData = {
        ...newData,
        baseSize: {
          width: size.width,
          height: size.height,
        },
      }
      isTouched = true
    }
    if (!data._actions) {
      newData = {
        ...newData,
        _actions: message.actions.map((a) => ({
          type: a.type,
          data: a.type === 'message' ? a.text : a.linkUri,
        })),
      }
      isTouched = true
    }
    if (isTouched) {
      onMessageChange(message, newData)
    }
    setReady(true)
  }

  const saveImagemap = () => {
    if (inputText === '') {
      setInput(message.altText)
      return
    }
    const imagemap = {
      ...message,
      altText: inputText,
    }
    onMessageChange(imagemap, data)
  }

  const handleImageChange = async (url) => {
    const size = await getImageResolution(url)
    if (size.width !== 1040) {
      AntMessage.error('รูปจำเป็นต้องมีความกว้าง 1,040 pixels !!')
      return
    }
    setDisplayImg(url)
    const imagemap = {
      type: 'imagemap',
      baseUrl: url + '?=ignore',
      altText: 'This is an imagemap',
      baseSize: {
        width: size.width,
        height: size.height,
      },
      actions: [],
    }
    onMessageChange(imagemap, {
      ...data,
      imageUrl: url,
      baseSize: { width: size.width, height: size.height },
    })
  }

  const handleActionsChange = (actions, _data) => {
    onMessageChange({ ...message, actions }, { ...data, ..._data })
  }

  if (!isReady && !loading) {
    mapDataToNewImagemap()
  }

  return (
    <div style={{ padding: '12px 24px' }}>
      <h4>รูปภาพ</h4>
      <small style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
        <InfoCircleOutlined style={{ marginRight: 4 }} />
        <span>รูปภาพ .jpg และ ขนาดน้อยกว่า 1 MB</span>
      </small>
      <ImageInput
        style={{ marginBottom: 8 }}
        botId={botId}
        value={displayImg}
        onChange={handleImageChange}
        loading={loading}
      />
      <div>
        <label style={{ fontWeight: 500 }}>Alt Text</label>
        <Input
          value={inputText}
          onChange={(e) => setInput(e.target.value)}
          maxLength={400}
          onBlur={saveImagemap}
          onPressEnter={saveImagemap}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            fontSize: '0.8em',
            fontWeight: 500,
            color: '#b7b7b7',
          }}
        >
          <span>{inputText.length} / 400</span>
        </div>
      </div>
      {data.imageUrl && (
        <ActionsEditor
          loading={loading}
          grid={data.grid}
          actions={data._actions}
          baseSize={data.baseSize}
          onChange={handleActionsChange}
        />
      )}
    </div>
  )
}

ImagemapEditor.propTypes = {
  botId: PT.string.isRequired,
  message: PT.object,
  data: PT.object,
  onMessageChange: PT.func,
  loading: PT.bool,
}

ImagemapEditor.defaultProps = {
  data: {},
  message: {},
  onMessageChange: () => {},
}

export default ImagemapEditor
