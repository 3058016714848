const moment = require('moment-timezone')

export const getMomentBangkok = (date) => {
  return moment.tz(date, 'Asia/Bangkok')
}

export const getCurrentTimeBangkok = () => {
  return moment().tz('Asia/Bangkok')
}

export const getMomentGMT0 = (date) => {
  return moment.tz(date, 'Etc/GMT+0')
}

export const getMomentGMT7 = (date) => {
  return moment.tz(date, 'Etc/GMT+7')
}

export const getMomentPlus7Hours = (date) => {
  return getMomentGMT0(date).add(7, 'hours')
}
