import React from 'react'

import './Special.css'
import { Grid, Spacer } from '../Layout'
import { FEATURES } from '../mock'
import { FeatureItem } from './FeatureItem'
import bgTop from '../assets/bgTop.png'
import bgBottom from '../assets/bgBottom.png'

type Props = {
  items?: any
}

export const Special = ({ items = FEATURES }: Props) => {
  return (
    <div className="page-container">
      <div className="bg-top">
        <img src={bgTop} width="100%" />
      </div>
      <div className="page-container--inner">
        <div className="subtitle">AIYA CUSTOMER RELATIONSHIP MANAGEMENT</div>
        <div className="title">พิเศษสุด ใช้งานระบบ ACRM ฟรี 30 วัน</div>
        <div className="description">
          <div>จบ! เรื่องการดูแลลูกค้า จากช่องทางโซเชียล</div>
          <div>มาพร้อม แชทบอท ช่วยดูแลลูกค้า 24 ชม.</div>
        </div>
        <Spacer height={30} />
        <div className="grid-feature">
          <Grid>
            {items.map((feature) => (
              <FeatureItem
                title={feature.body?.title || feature.title}
                pictureUrl={feature.body?.picture_url || feature.picture_url}
              />
            ))}
          </Grid>
        </div>
      </div>
      <div className="cover-bg-bottom">
        <img src={bgBottom} width="100%" />
      </div>
    </div>
  )
}

export default Special
