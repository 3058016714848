import { Button } from '@aiya/ui'
import React, { CSSProperties } from 'react'
import { Link } from './Link'

import LOGO_ACRM from '../assets/Logo/logo-aiya.png'

import './Footer.css'

type Props = {
  style?: CSSProperties
}

export const Footer = ({ style = {} }: Props) => {
  return (
    <div className="footer-live" style={{ ...style }}>
      <div className="footer-live--container">
        <div className="footer-live--left">
          <div className="logo">
            <img src={LOGO_ACRM} />
          </div>
          <div>เปิดประสบการณ์การใช้บริการที่เหนือกว่า</div>
          <div>จากผู้พัฒนานวัตกรรม ประสบการณ์มากกว่า 15 ปี</div>
          <div>Winner of Thailand ICT Awards 2019</div>
          <div>Merit of Asia Pacific ICT Awards 2019</div>
          <div>Gold Prize of Soul Innovation Invention Fair 2017</div>
        </div>
        <div className="footer-live--center">
          <h4>Link</h4>
          <div>
            <Link url="https://fb.com/aiyaclub">เฟสบุ๊ค</Link>
          </div>
          <div>
            <Link url="/terms-and-conditions">เงื่อนไขการใช้บริการ</Link>
          </div>
          <div>
            <Link url="/privacy">นโยบายความเป็นส่วนตัว</Link>
          </div>
          <div>
            <Link url="https://aiya.ai/lineoa">เปิดบัญชี LINE Official Account</Link>
          </div>
        </div>
        <div className="footer-live--right">
          <h4>Address</h4>
          <div>188 อาคารสปริงทาวเวอร์ (วีเวิร์ค ชั้น11)​</div>
          <div>ถนนพญาไท แขวงทุ่งพญาไท เขตราชเทวี กรุงเทพ 10400</div>
          <div>E-Mail: hello@aiya.ai</div>
          <div>Phone: (+66) 02 821 5699</div>
        </div>
      </div>
    </div>
  )
}
