import React from 'react'
import PT from 'prop-types'
import gql from 'graphql-tag'
import { UploadOutlined } from '@ant-design/icons'

import Button from './Button'

import { useUploadImageMutation } from '@aiya/data-access'

const ImageInput = ({ botId, value, onChange, alt, accept, style }) => {
  const fileUpload = React.createRef()
  const [loading, setLoading] = React.useState(false)

  const [uploadImage] = useUploadImageMutation({
    fetchPolicy: 'no-cache',
  })

  const getFilenameFromUrl = (uri) => {
    const qmIndex = uri.indexOf('?')
    const url = qmIndex !== -1 ? uri.substring(0, qmIndex) : uri

    const splited = url.split('/').filter((t) => !!t)
    let tmp = splited[splited.length - 1]

    return tmp
  }

  const handleBtnClick = () => {
    // @ts-ignore
    fileUpload.current.click()
  }

  const handleUpload = async (e) => {
    const file = e.target.files[0]
    setLoading(true)
    try {
      const { data } = await uploadImage({
        variables: {
          file,
        },
      })
      onChange(data.uploadImage.url)
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  return (
    <div style={style}>
      {value ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <img
            onClick={() => window.open(value, '_blank')}
            src={value}
            alt={alt}
            style={{ width: '180px', cursor: 'pointer' }}
          />
          <div style={{ width: 'calc(100% - 180px - 16px)' }}>
            <Button loading={loading} type="danger" onClick={handleBtnClick}>
              อัพโหลดใหม่
            </Button>
            {/* <small>{getFilenameFromUrl(value)}</small> */}
          </div>
        </div>
      ) : (
        <Button
          loading={loading}
          type="primary"
          ghost
          block
          icon={<UploadOutlined />}
          onClick={handleBtnClick}
        >
          อัพโหลดรูปภาพ
        </Button>
      )}

      <input
        // @ts-ignore
        ref={fileUpload}
        type="file"
        accept={accept}
        onChange={handleUpload}
        style={{ display: 'none' }}
      />
    </div>
  )
}

ImageInput.propTypes = {
  value: PT.string,
  onChange: PT.func,
  botId: PT.string,
  alt: PT.string,
  loading: PT.bool,
  accept: PT.string,
  style: PT.object,
}

ImageInput.defaultProps = {
  onChange: () => {},
  alt: 'preview',
  accept: 'image/*',
}

export default ImageInput
