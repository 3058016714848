export const BOT_TEMPLATES = [
  {
    title_th: 'ร้านอาหาร',
    title_en: 'Food&Drink Chatbot',
    icon_url: 'https://aiya.ai/static/images/chatbot-service/icon/dish.png',
    picture_url: 'https://aiya.ai/static/images/chatbot-service/example/dish.png',
    description:
      'แชทบอทสำหรับธุรกิจร้านอาหารและเครื่องดื่ม ที่จะมาช่วยธุรกิจของคุณในยุคที่ใครๆก็สั่งอาหารผ่านทางออนไลน์แบบนี้ โดยความสามารถของแชทบอททำได้มากกวาที่คุณคิด',
    features: [
      'ลิสต์เมนูของร้านให้ลูกค้าได้เลือกสรร',
      'เมื่อมีการสั่งออเดอร์ บอทจะแจ้งเตือนไปที่แอดมินให้มาสรุปยอด',
      'ลูกค้าสามารถเลือกวิธีการับอาหาร (Delivery/รับที่ร้าน)',
      'สามารถจองคิว/โต๊ะ เพื่อลดจำนวนลูกค้ารอคิวที่ร้าน',
    ],
  },
  {
    title_th: 'คลินิคเสริมความงาม',
    title_en: 'Beauty Clinic Chatbot',
    icon_url: 'https://aiya.ai/static/images/chatbot-service/icon/beauty.png',
    picture_url: 'https://aiya.ai/static/images/chatbot-service/example/beauty.png',
    description:
      'แชทบอทสำหรับธุรกิจคลินิกเสริมความงามที่จะช่วยลดจำนวนลูกค้าที่เข้าไปให้แพทย์ประเมินเบื้องต้นก่อนทำการศัลยกรรมหรือใช้บริการอื่นๆ อีกทั้งยังสามารถติดตามผลลูกค้ารายคนได้ผ่านแชทบอท แม้จะเปลี่ยนพนักงานดูแลก็ตาม',
    features: [
      'ลูกค้าส่งรูปใบหน้า บอทจะแจ้งเตือนไปที่แอดมิน ให้แพทย์ช่วยประเมินใบหน้าเบื้องต้น ก่อนนัดลูกค้าเข้าพบที่คลินิก',
      'สามารถจองคิวเพื่อทำการนัดแพทย์หรือจองคิวเข้าใช้บริการ',
      'ให้ข้อมูลบริการและผลิตภัณฑ์ต่างๆของคลินิก',
      'เลือกและสั่งซื้อผลิตภัณฑ์ จ่าย จบ ในแชทบอท',
    ],
  },
  {
    title_th: 'ร้านเสริมสวย',
    title_en: 'Haircut Chatbot',
    icon_url: 'https://aiya.ai/static/images/chatbot-service/icon/hair.png',
    picture_url: 'https://aiya.ai/static/images/chatbot-service/example/hair.png',
    description:
      'แชทบอทสำหรับธุรกิจร้านตัดผม ถ้าพูดถึงธุรกิจที่มีลูกค้ารอใช้บริการมากที่สุดในตอนนี้ 1 ในนั้นก็คือร้านตัดผม ด้วยความสามารถของแชทบอทที่มี จะทำให้ร้านตัดผมของคุณนั้นถูกใจลูกค้าแน่นอน',
    features: [
      'ลูกค้าสามารถจองคิวและเลือกบริการที่ต้องการทำก่อนเข้าใช้บริการ',
      'บอทแจ้งเตือนแอดมินเมื่อมีการจองเข้ามา',
      'เลือกและซื้อผลิตภัณฑ์ของทางร้าน จ่าย จบ ในแชทบอท',
      'ให้ข้อมูลบริการ โปรโมชั่น ข่าวสารต่างๆของทางร้าน',
    ],
  },
  {
    title_th: 'ร้านค้าออนไลน์',
    title_en: 'e-Commerce Chatbot',
    icon_url: 'https://aiya.ai/static/images/chatbot-service/icon/shop.png',
    picture_url: 'https://aiya.ai/static/images/chatbot-service/example/shop.png',
    description:
      'แชทบอทสำหรับธุรกิจค้าขายออนไลน์ ในยุคนี้มีแม่ค้าพ่อค้าออนไลน์เกิดขึ้นมากมาย ทำให้การแข่งขันสูงขึ้น การบริการจึงสำคัญต่อธุรกิจของคุณ แชทบอทจะมาช่วยให้บริการลูกค้าแบบ 24 ชั่วโมง ด้วยความสามารถแบบอัจฉริยะ ทันใจลูกค้าแน่นอน',
    features: [
      'แสดงรายการสินค้าของร้าน และสามารถดูรายละเอียดของสินค้าได้',
      'แจ้งเตือนไปยังแอดมินเมื่อลูกค้าต้องการความช่วยเหลือที่บอทตอบไม่ได้',
      'ลูกค้าสามารถ เลือกสินค้า จำนวน วิธีการจัดส่ง รวมถึงสั่งซื้อสินค้า จ่าย จบในแชทเดียว',
      'บอทสรุปออเดอร์ แจ้งเตือนไปยังแอดมินเมื่อมีการสั่งซื้อเข้ามา',
      'ให้ข้อมูลโปรโมชั่น ข่าวสารต่างๆของทางร้าน',
    ],
  },
]

export const FEATURES = [
  {
    content_type: 'feature',
    picture_url: 'https://drive.aiya.ai/b/f0d5fcfb70/images/1f1681129c886ec436a15ff4d13d4cbc.png',
    title: 'ออกแบบได้ตามต้องการ',
  },
  {
    content_type: 'feature',
    picture_url: 'https://drive.aiya.ai/b/f0d5fcfb70/images/102e56d8f244979683888bd8a292997f.png',
    title: 'แจ้งเตือนทุกคำสั่งซื้อ',
  },
  {
    content_type: 'feature',
    picture_url: 'https://drive.aiya.ai/b/f0d5fcfb70/images/aa028f9bbe5970fc456e571d857bed28.png',
    title: 'วิเคราะห์ความสนใจของลูกค้า',
  },
  {
    content_type: 'feature',
    picture_url: 'https://drive.aiya.ai/b/f0d5fcfb70/images/b6e6af5a0e1776be8a1d58a4e5467bab.png',
    title: 'บอร์ดแคสกระตุ้นยอดขาย',
  },
  {
    content_type: 'feature',
    picture_url: 'https://drive.aiya.ai/b/f0d5fcfb70/images/a3835fadd7a7d2970a1e8e66009f78e0.png',
    title: 'ติด Tag จัดกลุ่มเป้าหมาย อัตโนมัติ',
  },
  {
    content_type: 'feature',
    picture_url: 'https://drive.aiya.ai/b/f0d5fcfb70/images/e72a8f48ee8a2969fa742e9e80bb739c.png',
    title: 'แบบสอบถามผ่านช่องแชท',
  },
]

export const ALIVE_FEATURES = [
  {
    title_th: 'คนเดียวก็ไลฟ์ได้ไม่มีสะดุด เห็นทุกการเคลื่อนไหวบนหน้าจอเดียว',
    // title_en: 'Food&Drink Chatbot',
    // icon_url: '',
    picture_url: 'https://drive.aiya.ai/b/f0d5fcfb70/images/2223938bf7635750dd95d3095a22b18b.png',
    // description: '',
    features: [
      'มองเห็นสินค้า และจำนวนคงเหลือ',
      'เห็นทุกคอมเมนต์ขณะไลฟ์',
      'เพิ่มสินค้า เปลี่ยนคีย์เวิร์ดขณะไลฟ์',
      // 'ใครก่อกวนติด Backlist ได้',
    ],
  },
  {
    title_th: 'ดูดคอมเมนต์ คอนเฟริมออเดอร์ ไม่มีพลาด ด้วยระบบ AI',
    // title_en: 'Food&Drink Chatbot',
    // icon_url: '',
    picture_url: 'https://drive.aiya.ai/b/f0d5fcfb70/images/dc916a659e025abcfd760238b90499b2.png',
    // description: '',
    features: [
      'ระบบ AI ตรวจจับคอมเมนต์และตอบกลับทันทียัง Messenger ของผู้ CF ',
      'ช่วยสรุปคำสั่งซื้อ หลังไลฟ์ พร้อมเก็บหลักฐานการชำระเงินส่งกลับมายังระบบ',
      'แจ้งเตือนเมื่อมีการชำระเงิน หรือส่งหลักฐานการชำระเงิน',
    ],
  },
  {
    // title_th: 'มาพร้อมกับ AShop จะเปิดบิล จะคิดเงิน จะส่งของ ได้หมด',
    title_th: 'จะเปิดบิล จะคิดเงิน จะส่งของ ได้หมด',
    // title_en: 'Food&Drink Chatbot',
    // icon_url: '',
    picture_url: 'https://drive.aiya.ai/b/f0d5fcfb70/images/721106038937ea4d66791b6ef01b9dbf.png',
    // description: '',
    features: [
      'อัพเดตทุกสถานะคำสั่งซื้อ',
      // 'เปิดชำระผ่านระบบ QR Cash ลูกไม่ต้องอัพโหลดหลักฐาน',
      'สร้างรูปแบบการจัดส่ง อัพเดตสถานะการจัดส่งให้ลูกค้าอัตโนมัติ',
    ],
  },
]

// 1-1 https://drive.aiya.ai/b/f0d5fcfb70/images/61d7f239f1d9b095ade9e1784d7c781d.png
