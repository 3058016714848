import React from 'react'

import './FeatureItem.css'

export const FeatureItem = ({ title, pictureUrl }) => {
  return (
    <div className="feature-item-live">
      <img className="picture" src={pictureUrl} />
      <div className="feature-item-live--title">{title}</div>
    </div>
  )
}
