import React, { useState } from 'react'
import './PriceTable.css'

import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

// import './Details.scss'

const Container = styled.div`
  width: 100%;
  max-width: 1600px;
  background: #ffffff;
  margin: -80px auto 50px auto;
  position: relative;
  padding: 50px 0 50px 0;
`
const MobileTab = styled.div`
  width: 100px;
  background: ${(props) => props.bg || 'white'};
  border-radius: 10px;
  margin-left: -20px;
  z-index: ${(props) => props.z || -1};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.color || 'white'};
`
const MobileTabTitle = styled.p`
  transform: rotate(270deg);
  font-size: 24px;
  padding-top: 20px;
`

const CardHeader = styled.div`
  border-bottom: 1px solid #00000029;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.1em;
  height: 140px;
  color: ${(props) => props.color || 'inherit'};
  background: ${(props) => props.bg || 'transparent'};
`
const CardBody = styled.div`
  border-radius: 0 0 10px 10px;
  padding: 10px;
  line-height: 1.1em;
  font-size: 24px;
  text-align: center;
  color: ${(props) => props.color || 'inherit'};
  background: ${(props) => props.bg || 'transparent'};
`
const TextTitleBody = styled.p`
  margin-top: 30px;
  font-size: 16px;
`
const TextBody = styled.p`
  margin-top: 30px;
  font-size: 20px;
  margin: 10;
`
const MobileTextBody = styled.p`
  font-size: 20px;
  margin: 10;
`
const MobileDescBody = styled.p`
  font-size: 14px;
  margin: 10;
  margin-top: 30px;
`
const TextPriceHeader = styled.p`
  font-size: 18px;
  margin-top: 20px;
`
const TextPromotionHeader = styled.p`
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
  color: yellow;
`
const TextTitleHeader = styled.p`
  font-size: 40px;
`
const CardContainer = styled.div`
  position: relative;
  box-shadow: 0px 0px 5px #00000029;
  width: ${(props) => props.width || '200px'};
  border-radius: 10px;
  overflow: hidden;
  margin: ${(props) => (props.noMargin ? '0px' : '6px')};
  background: #f8f7f8;
  color: ${(props) => props.color || '#0095FF'};
  z-index: 20;
`

const data = [
  {
    id: 0,
    containerColor: '#8D8D8D',
    headerColor: '#FFFFFF',
    headerBg: '#DCDCDC',
    bodyBg: '#FFFFFF',
    package: 'FREE',
    price: 'รายเดือน',
    promotion: '',
    value: [
      '100 คน',
      '1 แอดมิน',
      '14 วัน',
      '7 วัน',
      '1 MB',
      '100 MB',
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
    ],
  },
  // {
  //   id: 1,
  //   containerColor: '#527EC6',
  //   headerColor: 'white',
  //   headerBg: '#527EC6',
  //   bodyBg: '#FFFFFF',
  //   package: 'BASIC',
  //   price: '฿590 mo',
  //   // promotion: 'รายปี ลด 40%',
  //   promotion: '',
  //   value: [
  //     '1,000 คน',
  //     '2 แอดมิน',
  //     '30 วัน',
  //     '180 วัน',
  //     '5 MB',
  //     '500 MB',
  //     <FontAwesomeIcon className="social" icon={faCheckCircle} />,
  //     <FontAwesomeIcon className="social" icon={faCheckCircle} />,
  //     <FontAwesomeIcon className="social" icon={faCheckCircle} />,
  //     <FontAwesomeIcon className="social" icon={faCheckCircle} />,
  //     <FontAwesomeIcon className="social" icon={faCheckCircle} />,
  //     <FontAwesomeIcon className="social" icon={faCheckCircle} />,
  //   ],
  // },
  // {
  //   id: 2,
  //   containerColor: 'white',
  //   headerBg: '#3166BC',
  //   bodyBg: '#527EC6',
  //   package: 'PRO',
  //   price: '฿990 mo',
  //   promotion: '',
  //   value: [
  //     '3,000 คน',
  //     '2 แอดมิน',
  //     '90 วัน',
  //     '180 วัน',
  //     '10 MB',
  //     '1 G',
  //     <FontAwesomeIcon className="social" icon={faCheckCircle} />,
  //     <FontAwesomeIcon className="social" icon={faCheckCircle} />,
  //     <FontAwesomeIcon className="social" icon={faCheckCircle} />,
  //     <FontAwesomeIcon className="social" icon={faCheckCircle} />,
  //     <FontAwesomeIcon className="social" icon={faCheckCircle} />,
  //     <FontAwesomeIcon className="social" icon={faCheckCircle} />,
  //   ],
  // },
  {
    id: 1,
    containerColor: '#527EC6',
    headerColor: 'white',
    headerBg: '#527EC6',
    bodyBg: '#FFFFFF',
    package: 'PRO',
    price: '฿990 mo',
    promotion: '',
    value: [
      '3,000 คน',
      '2 แอดมิน',
      '90 วัน',
      '180 วัน',
      '10 MB',
      '1 G',
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
    ],
  },
]

const dataKey = [
  'ระบบดูแลผู้ติดตาม',
  'ระบบจัดการสิทธิ์',
  'ระยะเวลาการจัดเก็บบทสนทนา',
  'ระยะเวลาการจัดเก็บรูปภาพ',
  'รองรับการส่งไฟล์',
  'ขนาดพื้นที่จัดเก็บไฟล์',
  'การเชื่อมต่อ Facebook',
  'การเชื่อมต่อ LINE OA',
  '1-on-1 Live Chat',
  'ระบบตอบกลับอัตโนมัติ',
  'รองรับการส่งรูปภาพ/สติ๊กเกอร์',
  'แอดมินทำงานร่วมกับบอท',
]

export const PriceTable = () => {
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <Container>
      <div className="has-text-centered">
        <p className="title title-block is-size-4 is-size-3-mobile is-marginless">
          Startup Packages
        </p>
      </div>

      <section className="hero is-hidden-touch">
        <div className="hero-body">
          <div className="container">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div>
                <CardContainer color="#1871B0" width="320px">
                  <CardHeader color="#3166BC">
                    <TextTitleHeader>PACKAGES</TextTitleHeader>
                  </CardHeader>
                  <CardBody style={{ textAlign: 'left' }}>
                    {dataKey.map((i) => (
                      <TextTitleBody key={i}>{i}</TextTitleBody>
                    ))}
                  </CardBody>
                </CardContainer>
              </div>

              {data.map((i) => (
                <div key={i.package}>
                  <CardContainer color={i.containerColor}>
                    <CardHeader bg={i.headerBg} color={i.headerColor}>
                      <TextTitleHeader>{i.package}</TextTitleHeader>
                      <TextPriceHeader>{i.price}</TextPriceHeader>
                      <TextPromotionHeader>{i.promotion}</TextPromotionHeader>
                    </CardHeader>
                    <CardBody bg={i.bodyBg}>
                      {i.value.map((j, index) => (
                        <TextBody key={index}>{j}</TextBody>
                      ))}
                    </CardBody>
                  </CardContainer>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="hero is-hidden-desktop">
        <div className="hero-body">
          <div className="container">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {[data[activeIndex]].map((i) => (
                <div key={i.package}>
                  <CardContainer color={i.containerColor} noMargin>
                    <CardHeader bg={i.headerBg} color={i.headerColor}>
                      <TextTitleHeader>{i.package}</TextTitleHeader>
                      <TextPriceHeader>{i.price}</TextPriceHeader>
                      <TextPromotionHeader>{i.promotion}</TextPromotionHeader>
                    </CardHeader>
                    <CardBody bg={i.bodyBg}>
                      {i.value.map((j, index) => (
                        <>
                          <MobileDescBody>{dataKey[index]}</MobileDescBody>
                          <MobileTextBody key={index}>{j}</MobileTextBody>
                        </>
                      ))}
                    </CardBody>
                  </CardContainer>
                </div>
              ))}
              {data
                .filter((i) => !(i.id === activeIndex))
                .map((j, index) => (
                  <MobileTab
                    bg={j.headerBg}
                    color={j.headerColor}
                    key={j.id}
                    z={8 - index}
                    onClick={() => {
                      setActiveIndex(j.id)
                    }}
                  >
                    <MobileTabTitle>{j.package}</MobileTabTitle>
                  </MobileTab>
                ))}
            </div>
          </div>
        </div>
      </section>
    </Container>
  )
}

export default PriceTable
