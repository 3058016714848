import React from 'react'
import PT from 'prop-types'

import IconButton from '../../../components/IconButton'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'

const ButtonItem = ({ onEdit, onDelete, data }) => {
  return (
    <div
      style={{
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        padding: '4px 8px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <span>{data.title}</span>
      <div style={{ display: 'flex' }}>
        {onEdit && (
          <IconButton onClick={onEdit}>
            <EditOutlined />
          </IconButton>
        )}
        {onDelete && (
          <IconButton onClick={onDelete} hoverColor="red">
            <DeleteOutlined />
          </IconButton>
        )}
      </div>
    </div>
  )
}

ButtonItem.propTypes = {
  onEdit: PT.func,
  onDelete: PT.func,
}

export default ButtonItem
