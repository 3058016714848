import React from 'react'
import PT from 'prop-types'

export interface Props {
  value: number
  style?: any
  prefix?: string
  suffix?: string
  clickToOpen?: () => void
}

export const NumberRenderer = ({ value = 0, style = {}, prefix, suffix }: Props) => {
  const stringNum = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return (
    <span style={style}>
      {!!prefix && <span style={{ marginRight: 4 }}>{prefix}</span>}
      <span>{stringNum}</span>
      {!!suffix && <span style={{ marginLeft: 4 }}>{suffix}</span>}
    </span>
  )
}

export default NumberRenderer
