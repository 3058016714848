import { DEFAULT_IMGMAP } from '../constants/defaultValue'

export const defaultLineData = {
  imagemap: {
    imageUrl: DEFAULT_IMGMAP,
    grid: {
      w: 1,
      h: 1,
    },
    baseSize: {
      width: 1040,
      height: 1040,
    },
    _actions: [
      {
        type: 'message',
        data: 'Imagemap Clicked',
      },
    ],
  },
  dynamicFlex: {
    apiType: 'default',
  },
  googleSheetFlex: {
    apiType: 'googleSheet',
  },
  productFlex: {
    apiType: 'product',
  },
  placeFlex: {
    apiType: 'place',
  },
  bookingFlex: {
    apiType: 'booking',
  },
}
