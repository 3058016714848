import React from 'react'

type Props = {
  children: any
  url?: string
  target?: string
}

export const Link = ({ children, url, target }: Props) => {
  return (
    <a
      href={url ? url : '#'}
      target={target ? target : '_blank'}
      style={{ color: 'white', textDecoration: 'none' }}
    >
      {children}
    </a>
  )
}
