import React from 'react'
import Loader from './images/loader.gif'

export const Loading = () => {
  return (
    <div style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
      <span
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <img src={Loader} alt="loading spinner" width="150" height="150" />
      </span>
    </div>
  )
}
