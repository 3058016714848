export const getEnv = () => {
  const host = window.location.host
  const [subdomain] = host.split('.')
  const [env] = subdomain.split('-')
  let result = env
  if (window.location.hostname === 'localhost') {
    result = 'localhost'
  }

  return result
}


export const getEnvChatHost = () => {
  let destHost = 'cloud-chat.aiya.ai'
  if (getEnv() === 'dev') {
    destHost = 'dev-cloud-chat.aiya.ai'
  } else if (getEnv() === 'uat') {
    destHost = 'uat-cloud-chat.aiya.ai'
  } else if (getEnv() === 'localhost:3002') {
    destHost = 'localhost:3001'
  }
  return destHost
}


