import React from 'react'
import PT from 'prop-types'
import { Input, message } from 'antd'
import Button from './Button'
import { useClipboard } from 'use-clipboard-copy'
import { jsonParse, stringify } from '@aiya/helper'

export const JSONInput = ({ data, onChange, loading }) => {
  const [input, setInput] = React.useState(JSON.stringify(data, null, 2))
  const [error, setError] = React.useState(false)
  const clipboard = useClipboard()

  React.useEffect(() => {
    setInput(JSON.stringify(data, null, 2))
  }, [data])

  const validateJSON = (str) => {
    try {
      setError(false)
      return JSON.parse(str)
    } catch (err) {
      setError(true)
      return false
    }
  }

  return (
    <div>
      <Input.TextArea
        placeholder="Paste JSON here.."
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onBlur={(e) => validateJSON(e.target.value) && onChange(validateJSON(e.target.value))}
        disabled={loading}
        autoSize={{ minRows: 4 }}
      />
      <div style={{display: "flex", marginTop: 10, justifyContent: "space-between"}}>
        {error ? (
          <small style={{ color: 'red' }}>Invalid JSON type!!</small>
        ) : (
          <small style={{ color: 'green' }}>Valid JSON type.</small>
        )}
        <div>
          <span style={{cursor: "pointer"}} onClick={() => {
            const payload = jsonParse(input)
            
            if (payload['type'] === 'flex') {
              clipboard.copy(stringify(payload['contents']))
            } else {
              clipboard.copy(input)
            }
            message.success('Copied!')
          }}>คัดลอก</span>
          {' | '}
          <a href="https://developers.line.biz/flex-simulator/" target="_blank">FlexSimulator</a>
        </div>
      </div>
    </div>
  )
}

JSONInput.propTypes = {
  data: PT.object,
  onChange: PT.func,
  loading: PT.bool,
}

JSONInput.defaultProps = {
  data: {},
  onChange: () => {},
}

export default JSONInput
