import { LINE_MSG_TYPE, FACEBOOK_MSG_TYPE } from './msgType'

export const TOOLBAR_LINE_DEFAULT = [
  LINE_MSG_TYPE.TEXT,
  LINE_MSG_TYPE.IMAGE,
  LINE_MSG_TYPE.IMAGEMAP,
  LINE_MSG_TYPE.FLEX,
  LINE_MSG_TYPE.DYNAMIC_FLEX,
  LINE_MSG_TYPE.GSHEET_FLEX,
  LINE_MSG_TYPE.PRODUCT_FLEX,
]

export const TOOLBAR_LINE_BROADCAST = [
  LINE_MSG_TYPE.TEXT,
  LINE_MSG_TYPE.IMAGE,
  LINE_MSG_TYPE.IMAGEMAP,
  LINE_MSG_TYPE.FLEX,
]

export const TOOLBAR_FACEBOOK_DEFAULT = [
  FACEBOOK_MSG_TYPE.TEXT,
  FACEBOOK_MSG_TYPE.IMAGE,
  FACEBOOK_MSG_TYPE.CAROUSEL,
  FACEBOOK_MSG_TYPE.DYNAMIC_CAROUSEL,
  // FACEBOOK_MSG_TYPE.BUTTON,
]

export const TOOLBAR_FACEBOOK_BROADCAST = [
  FACEBOOK_MSG_TYPE.TEXT,
  FACEBOOK_MSG_TYPE.IMAGE,
  FACEBOOK_MSG_TYPE.CAROUSEL,
]
