import React, { CSSProperties, useEffect, useState } from 'react'
import './Section.css'

type Props = {
  highlight?: boolean
  children: any
  style?: CSSProperties
}

export const Section = ({ children, highlight, style }: Props) => {
  const [classList, setClassList] = useState(['ai-page--section'])

  useEffect(() => {
    if (highlight) {
      setClassList(['ai-page--section', 'ai-page--section--highlight'])
    } else {
      setClassList(['ai-page--section'])
    }
  }, [highlight])

  return (
    <div className={classList.join(' ')} style={{ ...style }}>
      <div className="ai-page--section--container">{children}</div>
    </div>
  )
}
