import React, { useEffect, useState } from 'react'
import PT from 'prop-types'
import { Modal, Tooltip } from 'antd'
import {
  MenuOutlined,
  MessageOutlined,
  PictureOutlined,
  ProfileOutlined,
  ApiOutlined,
  CloseOutlined,
} from '@ant-design/icons'

import IconButton from '../../components/IconButton'
import IconMenu from '../../components/IconMenu'

import { defaultFacebookMsg } from '../../constants/defaultMsg'
import { FACEBOOK_MSG_TYPE } from '../../constants/msgType'

import ImageEditor from './ImageEditor'
import TextEditor from './TextEditor'
import CarouselEditor from './CarouselEditor'
import DynamicCarouselEditor from './DynamicCarouselEditor'
import CodeEditor from './CodeEditor'
import { MessageWrapper } from '../../types'
import { TOOLBAR_FACEBOOK_DEFAULT } from '../../constants/toolbar'

export const FacebookMessageEditor = ({
  index,
  botId,
  loading,
  toolbar = TOOLBAR_FACEBOOK_DEFAULT,
  messageObj,
  onChange,
  onDelete,
}) => {
  const { message, data, request } = messageObj
  const [toolbarButtons, setToolbarButtons] = useState([])

  const classifyMsg = (msg) => {
    if (!msg) {
      return
    }
    if (msg.text) {
      return FACEBOOK_MSG_TYPE.TEXT
    }
    const attachmentType = msg.attachment && msg.attachment.type

    if (attachmentType === 'image') {
      return FACEBOOK_MSG_TYPE.IMAGE
    } else if (attachmentType === 'template') {
      const attachmentPaylaod = (msg.attachment && msg.attachment.payload) || {}
      if (attachmentPaylaod.template_type === 'generic') {
        return request ? FACEBOOK_MSG_TYPE.DYNAMIC_CAROUSEL : FACEBOOK_MSG_TYPE.CAROUSEL
      } else if (attachmentPaylaod.template_type === 'button') {
        return FACEBOOK_MSG_TYPE.BUTTON
      }
    }
    return null
  }

  const [msgType, setMsgType] = React.useState(classifyMsg(message))

  const renderByType = [
    {
      type: FACEBOOK_MSG_TYPE.TEXT,
      title: 'Text Message',
      icon: <MessageOutlined />,
      editor: (
        <TextEditor
          message={message}
          onMessageChange={(msg) => handleMessageObjChange({ message: msg })}
          loading={loading}
        />
      ),
    },
    {
      type: FACEBOOK_MSG_TYPE.IMAGE,
      title: 'Image Message',
      icon: <PictureOutlined />,
      editor: (
        <ImageEditor
          message={message}
          onMessageChange={(msg) => handleMessageObjChange({ message: msg })}
          botId={botId}
          loading={loading}
        />
      ),
    },
    {
      type: FACEBOOK_MSG_TYPE.CAROUSEL,
      title: 'Carousel',
      icon: <ProfileOutlined />,
      editor: (
        <CarouselEditor
          botId={botId}
          message={message}
          onMessageChange={(msg) => handleMessageObjChange({ message: msg })}
          loading={loading}
        />
      ),
    },
    {
      type: FACEBOOK_MSG_TYPE.DYNAMIC_CAROUSEL,
      title: 'Dynamic Carousel',
      icon: <ApiOutlined />,
      editor: (
        <DynamicCarouselEditor
          messageObj={messageObj}
          onChange={(obj) => handleMessageObjChange(obj)}
          loading={loading}
        />
      ),
    },
    {
      type: FACEBOOK_MSG_TYPE.BUTTON,
      title: 'Code',
      icon: <ProfileOutlined />,
      editor: (
        <CodeEditor
          message={message}
          onMessageChange={(msg) => handleMessageObjChange({ message: msg })}
          loading={loading}
        />
      ),
    },
  ]

  const handleMsgTypeChange = (type) => {
    if (loading) {
      return
    }
    Modal.confirm({
      title: 'ยืนยันการแก้ไข',
      content: (
        <div>
          <div>การแก้ไขข้อความจะทำให้ข้อความในกล่องนี้หายไป</div>
          <div>เพื่อความปลอดภัยกรุณาสำรองข้อความเก่าไว้ก่อน</div>
        </div>
      ),
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
      onOk: () => {
        let newMessageObj = {
          name: messageObj.name,
          type: 'box',
          message: defaultFacebookMsg[type],
        } as MessageWrapper

        if (type === FACEBOOK_MSG_TYPE.DYNAMIC_CAROUSEL) {
          newMessageObj = {
            ...newMessageObj,
            type: 'API',
            request: {
              method: 'GET',
              uri: '',
              headers: {},
              variables: {},
            },
            mapping: 'message.attachment.payload.elements',
            element: '',
            child_mapping: '',
            child_element: '',
            payload: {},
          }
        }

        setMsgType(type)
        onChange(newMessageObj)
      },
      onCancel: () => {},
    })
  }

  const handleMessageObjChange = (item) => {
    const tmp = {
      ...messageObj,
      ...item,
    }
    onChange(tmp)
  }

  useEffect(() => {
    const { message, data, request } = messageObj
    setMsgType(classifyMsg(message))
  }, [messageObj])

  useEffect(() => {
    if (toolbar) {
      let buttons = renderByType.filter((component) => {
        return toolbar.includes(component.type)
      })
      setToolbarButtons(buttons)
    }
  }, [toolbar])

  return (
    <div
      style={{
        width: '100%',
        border: '1px solid rgba(0, 0, 0, 0.15)',
        borderRadius: 4,
        marginBottom: 8,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 32,
          borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
          padding: '0 4px',
        }}
      >
        <div style={{ display: 'flex' }}>
          <IconButton style={{ marginRight: 8 }}>
            <MenuOutlined />
          </IconButton>

          {toolbarButtons.map((obj) => (
            <Tooltip key={obj.type} title={obj.title}>
              <div>
                <IconMenu
                  key={obj.type}
                  onClick={() => handleMsgTypeChange(obj.type)}
                  active={msgType === obj.type}
                  style={{ marginRight: 4 }}
                >
                  {obj.icon}
                </IconMenu>
              </div>
            </Tooltip>
          ))}

          {/* <IconMenu
            onClick={() => handleMsgTypeChange(FACEBOOK_MSG_TYPE.TEXT)}
            active={msgType === FACEBOOK_MSG_TYPE.TEXT}
            style={{ marginRight: 4 }}
          >
            <MessageOutlined />
          </IconMenu>
          <IconMenu
            onClick={() => handleMsgTypeChange(FACEBOOK_MSG_TYPE.IMAGE)}
            active={msgType === FACEBOOK_MSG_TYPE.IMAGE}
            style={{ marginRight: 4 }}
          >
            <PictureOutlined />
          </IconMenu>
          <IconMenu
            onClick={() => handleMsgTypeChange(FACEBOOK_MSG_TYPE.CAROUSEL)}
            active={msgType === FACEBOOK_MSG_TYPE.CAROUSEL}
            style={{ marginRight: 4 }}
          >
            <ProfileOutlined />
          </IconMenu>
          <IconMenu
            onClick={() => handleMsgTypeChange(FACEBOOK_MSG_TYPE.DYNAMIC_CAROUSEL)}
            active={msgType === FACEBOOK_MSG_TYPE.DYNAMIC_CAROUSEL}
            style={{ marginRight: 4 }}
          >
            <ApiOutlined />
          </IconMenu> */}
        </div>
        {onDelete && (
          <IconButton hoverColor="red" onClick={onDelete}>
            <CloseOutlined />
          </IconButton>
        )}
      </div>

      {(renderByType.find((obj) => obj.type === msgType) || {}).editor || <div />}
      {/* {msgType === FACEBOOK_MSG_TYPE.TEXT && (
        <TextEditor
          message={message}
          onMessageChange={(msg) => handleMessageObjChange({ message: msg })}
          loading={loading}
        />
      )}
      {msgType === FACEBOOK_MSG_TYPE.IMAGE && (
        <ImageEditor
          botId={botId}
          data={data}
          message={message}
          onMessageChange={(msg) => handleMessageObjChange({ message: msg })}
          loading={loading}
        />
      )}
      {msgType === FACEBOOK_MSG_TYPE.CAROUSEL && (
        <CarouselEditor
          botId={botId}
          message={message}
          onMessageChange={(msg) => handleMessageObjChange({ message: msg })}
          loading={loading}
        />
      )}
      {msgType === FACEBOOK_MSG_TYPE.DYNAMIC_CAROUSEL && (
        <DynamicCarouselEditor
          messageObj={messageObj}
          onChange={(obj) => handleMessageObjChange(obj)}
          loading={loading}
        />
      )} */}
      <div
        style={{
          fontSize: '0.85em',
          borderTop: '1px solid rgba(0, 0, 0, 0.15)',
          padding: '0 8px',
        }}
      >
        <span style={{ fontWeight: 600, color: 'rgba(28, 112, 176, 1)' }}>{index + 1}</span>
      </div>
    </div>
  )
}

FacebookMessageEditor.propTypes = {
  botId: PT.string,
  index: PT.number,
  data: PT.object,
  message: PT.object,
  loading: PT.bool,
  onChange: PT.func,
  onDelete: PT.func,
}

FacebookMessageEditor.defaultProps = {
  onChange: () => {},
  // onDelete: () => {},
}

export default FacebookMessageEditor
