import React, { CSSProperties } from 'react'
import './Grid.css'

type Props = {
  children: any
  style?: CSSProperties
}

export const Grid = ({ children, style }: Props) => {
  return (
    <div className="grid-container" style={{ ...style }}>
      {children}
    </div>
  )
}
