import React from 'react'
import './Main.css'

export const Main = ({ children }) => {
  return (
    <div className="ai-page--main">
      <div className="ai-page--main--container">{children}</div>
    </div>
  )
}
