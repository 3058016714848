import React from 'react'
import PT from 'prop-types'
import { message } from 'antd'
import gql from 'graphql-tag'
import styled from 'styled-components'

import Button from './Button'
import { useMutation } from '@apollo/react-hooks'
import { getObjectUrl } from '@aiya/helper'
import { UploadOutlined, FileOutlined, DeleteOutlined } from '@ant-design/icons'

const TextButton = styled.span`
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    ${({ hoverColor }) => `color: ${hoverColor};`}
  }
`

const FILE_UPLOAD = gql`
  mutation fileUpload($file: Upload!, $botId: String!, $dir: String, $filename: String) {
    fileUpload(file: $file, bot_id: $botId, dirname: $dir, new_filename: $filename) {
      id
      filename
      mimetype
      encoding
      url
    }
  }
`

export const InputFile = ({
  loading: propsLoad,
  value,
  onChange,
  botId,
  btnProps,
  label,
  accept,
  newFilename,
  dirname,
}) => {
  const [loading, setLoading] = React.useState(false)
  const inputFile = React.useRef()

  const [fileUpload] = useMutation(FILE_UPLOAD, {
    onError: (err) => console.error(err),
    onCompleted: ({ fileUpload }) => {
      onChange(fileUpload.url)
      setLoading(false)
    },
  })

  const openUpload = () => {
    // @ts-ignore
    inputFile.current.click()
  }

  const handleUpload = async (file) => {
    if (file.size > 1024 * 1024 * 10) {
      message.error('ไฟล์ต้องมีขนาดไม่เกิน 10MB!!')
      return
    }
    setLoading(true)
    await fileUpload({
      variables: {
        file,
        botId,
        filename: newFilename && newFilename + file.name.substr(file.name.lastIndexOf('.')),
        dir: dirname,
      },
    })
  }

  return (
    <div>
      <Button
        icon={<UploadOutlined />}
        loading={propsLoad || loading}
        onClick={openUpload}
        ghost
        {...btnProps}
      >
        {label || 'อัพโหลดไฟล์'}
      </Button>

      {value && (
        <div style={{ marginTop: 8 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 4 }}>
            <a
              href={value}
              target="_blank"
              rel="noopener noreferrer"
              className="text-wrap-ellipsis"
              style={{ width: 'calc(100% - 80px)', textDecoration: 'underline' }}
            >
              <FileOutlined style={{ marginRight: 8 }} />
              <span>{getObjectUrl(value).filename}</span>
            </a>
            <TextButton hoverColor="red" onClick={() => onChange('')}>
              <DeleteOutlined style={{ marginRight: 4 }} />
            </TextButton>
          </div>
        </div>
      )}
      <input
        ref={inputFile}
        onChange={(e) => handleUpload(e.target.files[0])}
        type="file"
        accept={accept}
        style={{ display: 'none' }}
      />
    </div>
  )
}

InputFile.propTypes = {
  value: PT.string,
  onChange: PT.func,
  botId: PT.string,
  loading: PT.bool,
  btnProps: PT.object,
  label: PT.string,
  accept: PT.string,
  newFilename: PT.string,
  dirname: PT.string,
}

InputFile.defaultProps = {
  onChange: () => {},
  accept: '*',
}

export default InputFile
