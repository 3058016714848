// import React from 'react'
// import { css } from '@emotion/core'
// import { Link, graphql } from 'gatsby'

import React, { useEffect } from 'react'
// import { css } from '@emotion/core'
import { Link, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
// import { rhythm } from '../utils/typography'
import qs from 'querystring'

import 'antd/dist/antd.min.css'
import './index.scss'
import { isBrowser, isMobile } from 'react-device-detect'

import Logo from '../images/logo-alive.png'
const axios = require('axios')
// import Layout from '../components/layout'

import {
  Alive,
  Layout,
  Main,
  Grid,
  Section,
  Spacer,
  BOT_TEMPLATES,
  ALIVE_FEATURES,
} from '@aiya/aiya-web-ui'

import { Modal } from 'antd'

const {
  BotTemplate,
  FeatureItem,
  PriceTable,
  Cover,
  Footer,
  Testimonial,
  WhyUs,
  LinkButton,
  Special,
  Header,
} = Alive
import { getEnv, randomId, stringify, toBase64 } from '@aiya/helper'
import { LiffCore } from '@line/liff/dist/lib/liff'
import {
  useCreateAisRequestMutation,
  useSearchContentLazyQuery,
  useSearchContentQuery,
} from '@aiya/data-access'
import { withLocation } from '@aiya/aiya-web-ui'

type Props = {
  data: any
  navigate: any
}

const Home = ({ data, navigate }) => {
  let clientId = '1655259796'

  let createAisRequest

  if (typeof window !== 'undefined') {
    const [_createAisRequest] = useCreateAisRequestMutation()
    createAisRequest = _createAisRequest
  }

  const getHost = () => {
    let host = 'cloud.aiya.ai'
    if (getEnv() === 'dev') {
      host = `dev-${host}`
      clientId = '1655211289'
    } else if (getEnv() === 'uat') {
      host = `uat-${host}`
      clientId = '1655211289'
    } else if (getEnv() === 'localhost') {
      host = `localhost:3002`
      clientId = '1655211289'
    }
    return host
  }

  const handleSignUp = (data) => {
    const host = getHost()
    const q = toBase64(stringify(data))
    const url = `https://${host}/register/alive`
    const query = {
      source: 'ais-live',
      q,
    }
    navigate(`${url}?${qs.stringify(query)}`)

    // createAisRequest({
    //   variables: {
    //     input: {
    //       ...data,
    //     },
    //   },
    // })
    //   .then((res) => {
    //     console.log(res)
    //     Modal.success({
    //       content: (
    //         <>
    //           <div>ลงทะเบียนเรียบร้อย</div>
    //           <div>ระบบจะยืนยันสิทธิ์ภายใน 3 วันทำการทาง LINE Official</div>
    //           <div>กรุณาเพิ่ม @aiyaclub เป็นเพื่อนทางไลน์</div>
    //         </>
    //       ),
    //       onOk: () => {
    //         if (isMobile) {
    //           navigate(`https://line.me/R/oaMessage/@aiyaclub/?${data.phone}`)
    //         } else {
    //           navigate(`https://lin.ee/f1IsTXJ`)
    //         }
    //       },
    //     })
    //   })
    //   .catch((err) => {
    //     console.error(err)
    //     Modal.error({
    //       title: 'เกิดข้อผิดพลาด',
    //       content: (
    //         <>
    //           <div>อาจเกิดจากเบอร์โทรของท่านได้ลงทะเบียนไว้แล้ว</div>
    //           <div>หากต้องการคุยกับเจ้าหน้าที่ สามารถติดต่อได้ทางไลน์ @aiyaclub</div>
    //         </>
    //       ),
    //     })
    //   })
  }

  const handleLogin = () => {
    const host = getHost()
    const url = `https://${host}/login`
    const query = {
      ref: 'ais-live',
    }
    navigate(`${url}?${qs.stringify(query)}`)
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <>
      <Helmet>
        <title>AIYA</title>
        <link href="/favicon.ico" rel="shortcut icon" type="image/x-icon" />
        <meta http-equiv="Cache-control" content="public, max-age=0, must-revalidate" />
      </Helmet>
      <Layout>
        <Header logoUrl={Logo} onButtonClick={scrollToTop} onLoginClick={handleLogin} />
        <Main>
          <Cover onSignUp={handleSignUp} />
          <Section>
            <WhyUs />
            <Spacer height={30} />
            <BotTemplate items={ALIVE_FEATURES} />
          </Section>
          {/* <Testimonial /> */}
          <Special items={data?.aiya?.searchContent?.data} />
          {/* <Grid>
              {data?.aiya?.searchContent?.data?.map((feature, key) => (
                <FeatureItem
                  key={key}
                  title={feature.body.title}
                  pictureUrl={feature.body.picture_url}
                />
              ))}
            </Grid> */}
          <Section>
            <div className="title">ค่าบริการรายเดือน</div>
            <Spacer height={30} />
            <PriceTable />
          </Section>
          <Section highlight>
            <div className="title">สอบถามข้อมูลเพิ่มเติม</div>
            <div className="description">
              หากมีข้อสงสัยใดเกี่ยวกับผลิตภัณฑ์ของเรา สามารถแชทพูดคุยสอบถามได้ตลอด 24 ช.ม.
            </div>
            <div style={{ textAlign: 'center', marginTop: 20 }}>
              <LinkButton url="https://lin.ee/f1IsTXJ">ติดต่อฝ่ายลูกค้าสัมพันธ์</LinkButton>
            </div>
          </Section>
          <Footer />
        </Main>
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    aiya {
      searchContent(input: { filters: { content_type: "features" } }) {
        data {
          body
          title
          id
        }
      }
    }
  }
`

export default withLocation(Home)
