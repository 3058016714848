import React from 'react'
import PT from 'prop-types'
import { Input } from 'antd'

import InputField from '../../../components/InputField'

const PostbackForm = ({ qrAction, onChange }) => {
  const [input, setInput] = React.useState(qrAction)

  React.useEffect(() => {
    setInput(qrAction)
  }, [qrAction])

  const inputChange = (item) => {
    setInput({ ...input, ...item })
  }

  const handleActionChange = () => {
    onChange(input)
  }

  return (
    <>
      <InputField title="ชื่อปุ่ม">
        <Input
          value={input.label}
          onChange={(e) => inputChange({ label: e.target.value })}
          onBlur={handleActionChange}
          onPressEnter={handleActionChange}
          maxLength={20}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <small style={{ fontWeight: 500, color: '#b7b7b7' }}>
            {(input.label || '').length} / 20
          </small>
        </div>
      </InputField>
      <InputField title="ข้อความ">
        <Input.TextArea
          value={input.displayText}
          onChange={(e) => inputChange({ displayText: e.target.value })}
          onBlur={handleActionChange}
          onPressEnter={handleActionChange}
          autoSize={{ minRows: 1 }}
          maxLength={300}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <small style={{ fontWeight: 500, color: '#b7b7b7' }}>
            {(input.displayText || '').length} / 300
          </small>
        </div>
      </InputField>
      <InputField title="ข้อมูลที่จะส่ง">
        <Input.TextArea
          value={input.data}
          onChange={(e) => inputChange({ data: e.target.value })}
          onBlur={handleActionChange}
          onPressEnter={handleActionChange}
          autoSize={{ minRows: 1 }}
          maxLength={300}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <small style={{ fontWeight: 500, color: '#b7b7b7' }}>
            {(input.data || '').length} / 300
          </small>
        </div>
      </InputField>
    </>
  )
}

PostbackForm.propTypes = {
  qrAction: PT.object,
  onChange: PT.func,
}

PostbackForm.defaultProps = {
  onChange: () => {},
}

export default PostbackForm
