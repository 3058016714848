import React from 'react'
import './LinkButton.css'

type Props = {
  children: any
  url?: string
  target?: string
}

export const LinkButton = ({ children, url, target }: Props) => {
  return (
    <a
      href={url ? url : '#'}
      target={target ? target : '_blank'}
      // className="button is-primary is-medium is-rounded is-inverted"
      className="button is-primary is-rounded is-inverted"
    >
      {children}
    </a>
  )

  // return <div className="button is-primary is-rounded is-inverted">{children}</div>
}
