import React, { useEffect, useState } from 'react'
import PT from 'prop-types'
import { InputNumber } from 'antd'

import ActionItem from './ActionItem'
import Button from '../../../components/Button'
import InputField from '../../../components/InputField'
import { SettingFilled } from '@ant-design/icons'
import { Grid, Actions, Action } from './types'

type Props = {
  grid: Grid
  actions: Actions
  baseSize: {
    width: number
    height: number
  }
  loading: boolean
  onChange: (action: Action, { grid: Grid, actions: Actions }) => void
}

const ActionsEditor = ({ grid, actions, baseSize, loading, onChange }: Props) => {
  const [input, setInput] = useState({
    grid,
    actions,
    baseSize,
  })

  const [numRow, setNumRow] = useState(grid.w)
  const [numCol, setNumCol] = useState(grid.h)

  const getAreaFromGridIndex = (grid, baseSize, index) => {
    const width = baseSize.width / grid.w
    const height = baseSize.height / grid.h
    return {
      x: width * (index % grid.w),
      y: height * Math.floor(index / grid.w),
      width,
      height,
    }
  }

  const actionChange = (action, index) => {
    let tmp = [...input.actions]
    tmp[index] = { ...tmp[index], ...action }
    inputChange({ actions: tmp })
  }

  const generateGrid = () => {
    const num = numRow * numCol
    let actions = []
    for (let i = 0; i < num; i++) {
      actions.push({
        type: 'none',
        data: '',
      })
    }
    inputChange({ grid: { w: numRow, h: numCol }, actions })
  }

  const inputChange = (item) => {
    setInput({ ...input, ...item })
  }

  useEffect(() => {
    if (input) {
      const { actions } = input
      const tmp = actions
        .map((a, i) =>
          a.type === 'none'
            ? undefined
            : {
                type: a.type,
                [a.type === 'uri' ? 'linkUri' : 'text']: a.data,
                area: getAreaFromGridIndex(grid, baseSize, i),
              }
        )
        .filter((a) => a != null)
      // @ts-ignore
      onChange(tmp, { _actions: actions, grid: { w: numRow, h: numCol } })
    }
  }, [input])

  return (
    <div>
      <InputField title="Grid (กว้าง x สูง)" style={{ marginBottom: 16 }}>
        <div>
          <InputNumber
            value={numRow}
            onChange={(val) => {
              setNumRow(Number(val))
            }}
            min={1}
            style={{ width: 60 }}
            disabled={loading}
          />
          <span style={{ marginLeft: 4, marginRight: 4 }}>x</span>
          <InputNumber
            value={numCol}
            onChange={(val) => {
              setNumCol(Number(val))
            }}
            min={1}
            style={{ width: 60, marginRight: 8 }}
            disabled={loading}
          />
          <Button
            onClick={generateGrid}
            type="primary"
            icon={<SettingFilled />}
            loading={loading}
          />
        </div>
      </InputField>
      {input.actions.map((a, i) => (
        <ActionItem
          key={i}
          index={i + 1}
          action={a}
          onChange={(val) => actionChange(val, i)}
          loading={loading}
        />
      ))}
    </div>
  )
}

export default ActionsEditor
/*
class ActionsEditor2 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      grid: props.initial.grid || { w: 1, h: 1 },
      actions: props.initial.actions || [],
    }
  }

  getAreaFromGridIndex = (grid, baseSize, index) => {
    const width = baseSize.width / grid.w
    const height = baseSize.height / grid.h
    return {
      x: width * (index % grid.w),
      y: height * Math.floor(index / grid.w),
      width,
      height,
    }
  }

  actionChange = (action, index) => {
    let tmp = [...this.state.actions]
    tmp[index] = { ...tmp[index], ...action }
    this.setState({ actions: tmp })
  }

  gridChange = (item) => {
    const { grid } = this.state
    this.setState({ grid: { ...grid, ...item } })
  }

  generateGrid = () => {
    const { grid } = this.state
    const num = grid.w * grid.h
    let tmp = []
    for (let i = 0; i < num; i++) {
      tmp.push({
        type: 'none',
        data: '',
      })
    }
    this.setState({ actions: tmp })
  }

  componentDidUpdate(prevProps, prevState) {
    const { baseSize, onChange } = this.props
    const { actions, grid } = this.state
    if (prevProps.baseSize !== baseSize || prevState.actions !== actions) {
      const tmp = actions
        .map((a, i) =>
          a.type === 'none'
            ? undefined
            : {
                type: a.type,
                [a.type === 'uri' ? 'linkUri' : 'text']: a.data,
                area: this.getAreaFromGridIndex(grid, baseSize, i),
              }
        )
        .filter((a) => a != null)
      onChange(tmp, { _actions: actions, grid })
    }
  }

  render() {
    const { loading } = this.props
    const { grid, actions } = this.state
    return (
      <div>
        <InputField title="Grid (กว้าง x สูง)" style={{ marginBottom: 16 }}>
          <div>
            <InputNumber
              value={grid.w}
              onChange={(val) => this.gridChange({ w: val })}
              min={1}
              style={{ width: 60 }}
              disabled={loading}
            />
            <span style={{ marginLeft: 4, marginRight: 4 }}>x</span>
            <InputNumber
              value={grid.h}
              onChange={(val) => this.gridChange({ h: val })}
              min={1}
              style={{ width: 60, marginRight: 8 }}
              disabled={loading}
            />
            <Button
              onClick={this.generateGrid}
              type="primary"
              icon={<SettingFilled />}
              loading={loading}
            />
          </div>
        </InputField>
        {actions.map((a, i) => (
          <ActionItem
            key={i}
            index={i + 1}
            action={a}
            onChange={(val) => this.actionChange(val, i)}
            loading={loading}
          />
        ))}
      </div>
    )
  }
}

ActionsEditor.propTypes = {
  initial: PT.shape({
    grid: PT.shape({ w: PT.number, h: PT.number }),
    actions: PT.arrayOf(
      PT.shape({
        type: PT.string,
        data: PT.string,
      })
    ),
  }),
  baseSize: PT.shape({
    width: PT.number,
    height: PT.number,
  }),
  onChange: PT.func,
  loading: PT.bool,
}

ActionsEditor.defaultProps = {
  onChange: () => {},
}

export default ActionsEditor
*/
