export const LINE_MSG_TYPE = {
  TEXT: 'text',
  IMAGE: 'image',
  RICH_MESSAGE: 'richMessage',
  IMAGEMAP: 'imagemap',
  FLEX: 'flex',
  DYNAMIC_FLEX: 'dynamicFlex',
  GSHEET_FLEX: 'googleSheetFlex',
  PRODUCT_FLEX: 'productFlex',
  PLACE_FLEX: 'placeFlex',
  BOOKING_FLEX: 'bookingFlex',
  QUICKREPLY: 'quickReply',
}

export const FACEBOOK_MSG_TYPE = {
  TEXT: 'text',
  IMAGE: 'image',
  CAROUSEL: 'carousel',
  BUTTON: 'button',
  DYNAMIC_CAROUSEL: 'dynamicCarousel',
  QUICKREPLY: 'quickReply',
}

export const lineQuickReplyType = {
  MESSAGE: 'message',
  POSTBACK: 'postback',
  // DATETIME: 'datetimepicker',
  // CAMERA: 'camera',
  // CAMERAROLL: 'cameraRoll',
  LOCATION: 'location',
}

export const facebookQuickReplyType = {
  TEXT: 'text',
  PHONE: 'user_phone_number',
  EMAIL: 'user_email',
}
