import React from 'react'
import PT from 'prop-types'
import { Input, Modal } from 'antd'

import InputField from '../../../components/InputField'
import ImageInput from '../../../components/ImageInput'
import ButtonItem from './ButtonItem'
import ButtonForm from './ButtonForm'
import { PlusCircleOutlined } from '@ant-design/icons'

const CardItem = ({ botId, element, onChange }) => {
  const [modalState, setModal] = React.useState(-1)
  const [input, setInput] = React.useState(element)
  const [btnInput, setBtnInput] = React.useState({})

  let buttons = element.buttons || []

  React.useEffect(() => {
    setInput(element)
  }, [element])

  const inputChange = (item) => {
    setInput({ ...input, ...item })
  }

  const inputBtnChange = (item) => {
    setBtnInput({ ...btnInput, ...item })
  }

  const handleCardChange = (overrideInput = {}) => {
    onChange({ ...input, ...overrideInput })
  }

  const handleButtonEditClick = (index) => {
    setModal(index)
    const btnData = (element.buttons || [])[index]
    setBtnInput(btnData)
  }

  const handleAddBtn = () => {
    const newBtn = {
      type: 'postback',
      title: 'Start Chatting',
      payload: 'Get Started',
    }
    onChange({ ...input, buttons: [...buttons, newBtn] })
  }

  const handleEditBtn = () => {
    let tmp = [...buttons]
    tmp[modalState] = btnInput
    onChange({ ...input, buttons: tmp })
    setBtnInput({})
    setModal(-1)
  }

  const handleDelBtn = (index) => {
    let tmp = [...buttons]
    tmp.splice(index, 1)
    onChange({ ...input, buttons: tmp })
  }

  return (
    <div>
      <InputField title="หัวข้อ">
        <Input
          value={input.title}
          onChange={(e) => inputChange({ title: e.target.value || undefined })}
          onBlur={() => handleCardChange()}
          onPressEnter={() => handleCardChange()}
        />
      </InputField>
      <InputField title="คำอธิบาย">
        <Input
          value={input.subtitle}
          onChange={(e) => inputChange({ subtitle: e.target.value || undefined })}
          onBlur={() => handleCardChange()}
          onPressEnter={() => handleCardChange()}
        />
      </InputField>
      <InputField title="รูปภาพ">
        <ImageInput
          botId={botId}
          value={element.image_url}
          onChange={(url) => handleCardChange({ image_url: url || undefined })}
        />
      </InputField>
      <InputField title={`ปุ่ม (${(element.buttons || []).length} / 3)`}>
        <div
          style={{ border: '1px solid rgba(0, 0, 0, 0.1)', borderRadius: 4, overflow: 'hidden' }}
        >
          {buttons.map((btn, i) => (
            <ButtonItem
              key={i}
              data={btn}
              onEdit={() => handleButtonEditClick(i)}
              onDelete={buttons.length > 1 ? () => handleDelBtn(i) : undefined}
            />
          ))}
        </div>
        {buttons.length < 3 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              fontWeight: 500,
              marginTop: 8,
            }}
            onClick={handleAddBtn}
          >
            <PlusCircleOutlined style={{ marginRight: 4 }} />
            <span style={{ textDecoration: 'underline' }}>เพิ่มปุ่ม</span>
          </div>
        )}
      </InputField>

      <Modal
        title="แก้ไขข้อมูลปุ่ม"
        visible={modalState !== -1}
        onCancel={() => setModal(-1)}
        onOk={handleEditBtn}
      >
        <ButtonForm input={btnInput} inputChange={inputBtnChange} />
      </Modal>
    </div>
  )
}

CardItem.propTypes = {
  botId: PT.string,
  imageUrl: PT.string,
  element: PT.object,
}

CardItem.defaultProps = {
  element: {},
}

export default CardItem
