import React from 'react'
import PT from 'prop-types'

import Button from '../../components/Button'
import IconButton from '../../components/IconButton'
import { defaultFacebookQR } from '../../constants/defaultMsg'
import { facebookQuickReplyType } from '../../constants/msgType'

import TextForm from './Form/TextForm'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'

const MAX_ITEMS = 13
const ITEM_SIZE = 360

export const FacebookQuickReplyEditor = ({ items, onChange }) => {
  const handleItemChange = (item, index) => {
    updateItem(item, index)
  }

  const addItem = () => {
    const newItem = defaultFacebookQR.text
    const tmp = [...items, newItem]
    onChange(tmp)
  }

  const updateItem = (qr, indexToUpdate) => {
    let tmp = [...items]
    tmp[indexToUpdate] = qr
    onChange(tmp)
  }

  const removeItem = (indexToRemove) => {
    let tmp = [...items]
    tmp.splice(indexToRemove, 1)
    onChange(tmp)
  }

  return (
    <div>
      <div style={{ marginBottom: 8 }}>
        Quick Reply ( {items.length} / {MAX_ITEMS} )
      </div>
      {items.map((qr, i) => {
        return (
          <div
            key={i}
            style={{
              width: ITEM_SIZE,
              display: 'flex',
              flexDirection: 'column',
              margin: '8px auto',
              border: '1px solid rgba(0, 0, 0, 0.15)',
              borderRadius: 4,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                padding: '4px 8px',
                borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
              }}
            >
              {/* <span>
              <span style={{ marginRight: 8 }}>Type:</span>
              <Select
                size="small"
                style={{ minWidth: 120 }}
                value={(qr.action || {}).type}
                onChange={val => handleTypeChange(val, i)}
              >
                {Object.keys(lineQuickReplyType).map(key => (
                  <Select.Option key={lineQuickReplyType[key]}>
                    {lineQuickReplyType[key]}
                  </Select.Option>
                ))}
              </Select>
            </span> */}
              <IconButton hoverColor="red" onClick={() => removeItem(i)}>
                <CloseOutlined />
              </IconButton>
            </div>
            <div style={{ padding: '12px 24px' }}>
              <TextForm qrItem={qr} onChange={(item) => handleItemChange(item, i)} />
            </div>
            <div
              style={{
                fontSize: '0.85em',
                borderTop: '1px solid rgba(0, 0, 0, 0.15)',
                padding: '0 8px',
              }}
            >
              <span style={{ fontWeight: 600, color: 'rgba(28, 112, 176, 1)' }}>{i + 1}</span>
            </div>
          </div>
        )
      })}
      {items.length < MAX_ITEMS && (
        <div style={{ textAlign: 'center' }}>
          <Button icon={<PlusOutlined />} onClick={addItem}>
            เพิ่ม Quick Reply
          </Button>
        </div>
      )}
    </div>
  )
}

FacebookQuickReplyEditor.propTypes = {
  items: PT.arrayOf(
    PT.shape({
      content_type: PT.oneOf(
        Object.keys(facebookQuickReplyType).map((k) => facebookQuickReplyType[k])
      ),
      title: PT.string,
    })
  ),
  onChange: PT.func,
}

FacebookQuickReplyEditor.defaultProps = {
  items: [],
  onChange: () => {},
}

export default FacebookQuickReplyEditor
