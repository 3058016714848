import React from 'react'
import PT from 'prop-types'
import { Row, Col, Input } from 'antd'
import styled from 'styled-components'
import { PlusCircleOutlined, CloseCircleFilled } from '@ant-design/icons'
import { Loading } from './Loading'

const DeleteBtn = styled.span`
  cursor: pointer;
  &:hover {
    color: red;
  }
`

const AddTextBtn = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const KeyValueInput = ({ data, onChange, onSubmit, loading }) => {
  const [arr, setArr] = React.useState(Object.keys(data).map((k) => ({ key: k, value: data[k] })))

  React.useEffect(() => {
    setArr(Object.keys(data).map((k) => ({ key: k, value: data[k] })))
  }, [data])

  const mapToObj = (arr) => {
    let obj = {}
    arr.forEach((a) => {
      obj = { ...obj, [a.key]: a.value }
    })
    return obj
  }

  const onKeyChange = (val, index) => {
    let tmp = [...arr]
    tmp[index] = { ...tmp[index], key: val }
    onChange(mapToObj(tmp))
  }

  const onValueChange = (val, index) => {
    let tmp = [...arr]
    tmp[index] = { ...tmp[index], value: val }
    onChange(mapToObj(tmp))
  }

  const onAddField = () => {
    const emptyKeyField = arr.find((a) => a.key === '')
    if (emptyKeyField) {
      return
    }
    const tmp = [...arr, { key: '', value: '' }]
    onChange(mapToObj(tmp))
    onSubmit(mapToObj(tmp))
  }

  const onDelField = (index) => {
    let tmp = [...arr]
    tmp.splice(index, 1)
    onChange(mapToObj(tmp))
    onSubmit(mapToObj(tmp))
  }

  return (
    <>
      {arr.map((a, i) => (
        <div key={i} style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
          <Row gutter={8} style={{ width: 'calc(100% - 24px)', marginRight: 8 }}>
            <Col span={12}>
              <Input
                value={a.key}
                onChange={(e) => onKeyChange(e.target.value, i)}
                onBlur={() => onSubmit(mapToObj(arr))}
                onPressEnter={() => onSubmit(mapToObj(arr))}
                placeholder="Key"
                disabled={loading}
              />
            </Col>
            <Col span={12}>
              <Input
                value={a.value}
                onChange={(e) => onValueChange(e.target.value, i)}
                onBlur={() => onSubmit(mapToObj(arr))}
                onPressEnter={() => onSubmit(mapToObj(arr))}
                placeholder="Value"
                disabled={loading}
              />
            </Col>
          </Row>
          <DeleteBtn onClick={() => !loading && onDelField(i)}>
            <CloseCircleFilled />
          </DeleteBtn>
        </div>
      ))}
      <div style={{ textAlign: 'center' }}>
        <AddTextBtn onClick={() => !loading && onAddField()}>
          {loading ? (
            <div style={{ marginRight: 8 }}>
              {' '}
              <Loading />
            </div>
          ) : (
            <div style={{ marginRight: 8 }}>
              {' '}
              <PlusCircleOutlined />
            </div>
          )}
          <span>เพิ่ม Field</span>
        </AddTextBtn>
      </div>
    </>
  )
}

KeyValueInput.propTypes = {
  data: PT.objectOf(PT.string),
  onChange: PT.func,
  onSubmit: PT.func,
  loading: PT.bool,
}

KeyValueInput.defaultProps = {
  data: {},
  onChange: () => {},
  onSubmit: () => {},
}

export default KeyValueInput
