import React from 'react'
import PT from 'prop-types'
import { Divider, Select } from 'antd'

import IconButton from '../../../components/IconButton'
import CardItem from './CardItem'
import InputField from '../../../components/InputField'
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  PlusCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons'

const CarouselEditor = ({ botId, message, onMessageChange }) => {
  const [currIndex, setCurrIndex] = React.useState(0)

  const messagePayload = (message.attachment || {}).payload || {}
  const imageAspectRatio = messagePayload.image_aspect_ratio || 'horizontal'
  const elements = messagePayload.elements || []

  const prevCard = () => {
    if (currIndex === 0) {
      return
    }
    setCurrIndex(currIndex - 1)
  }

  const nextCard = () => {
    if (currIndex === elements.length - 1) {
      return
    }
    setCurrIndex(currIndex + 1)
  }

  const handleImageRatioChange = (ratio) => {
    const msg = {
      ...message,
      attachment: {
        ...message.attachment,
        payload: {
          ...messagePayload,
          image_aspect_ratio: ratio,
        },
      },
    }
    onMessageChange(msg)
  }

  const handleAddCard = () => {
    const newCard = {
      title: 'Title',
      subtitle: 'Subtitle',
      buttons: [
        {
          type: 'postback',
          title: 'Action',
          payload: 'Action',
        },
      ],
    }
    const tmp = [...elements, newCard]
    const msg = {
      ...message,
      attachment: {
        ...message.attachment,
        payload: {
          ...messagePayload,
          elements: tmp,
        },
      },
    }

    onMessageChange(msg)
  }

  const handleEditCard = (input) => {
    let tmp = [...elements]
    tmp[currIndex] = input
    const msg = {
      ...message,
      attachment: {
        ...message.attachment,
        payload: {
          ...messagePayload,
          elements: tmp,
        },
      },
    }
    onMessageChange(msg)
  }

  const handleDelCard = () => {
    let tmp = [...elements]
    tmp.splice(currIndex, 1)
    const msg = {
      ...message,
      attachment: {
        ...message.attachment,
        payload: {
          ...messagePayload,
          elements: tmp,
        },
      },
    }
    onMessageChange(msg)
    prevCard()
  }

  return (
    <>
      <div style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)', padding: 16 }}>
        <InputField title="Image Aspect Ratio">
          <Select
            style={{ width: '100%' }}
            value={imageAspectRatio}
            onChange={handleImageRatioChange}
          >
            <Select.Option key="horizontal" value="horizontal">
              Horizontal / 1.91 : 1
            </Select.Option>
            <Select.Option key="square" value="square">
              Square / 1 : 1
            </Select.Option>
          </Select>
        </InputField>
      </div>
      <div style={{ padding: '12px 24px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 600,
            fontSize: '1.1em',
            marginBottom: 8,
          }}
        >
          <IconButton disabled={currIndex === 0} onClick={prevCard} style={{ marginRight: 16 }}>
            <ArrowLeftOutlined />
          </IconButton>
          <span>การ์ดใบที่ ({currIndex + 1} / 10)</span>
          <IconButton
            disabled={currIndex === elements.length - 1}
            onClick={nextCard}
            style={{ marginLeft: 16 }}
          >
            <ArrowRightOutlined />
          </IconButton>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {elements.length === 10 || (
            <div
              onClick={handleAddCard}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                color: '#1C70B0',
                fontWeight: 600,
              }}
            >
              <PlusCircleOutlined style={{ marginRight: 4 }} />
              <span style={{ textDecoration: 'underline' }}>เพิ่มการ์ด</span>
            </div>
          )}
          {elements.length === 1 || elements.length === 10 || <Divider type="vertical" />}
          {elements.length === 1 || (
            <div
              onClick={handleDelCard}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                color: 'red',
                fontWeight: 600,
              }}
            >
              <CloseCircleOutlined style={{ marginRight: 4 }} />
              <span style={{ textDecoration: 'underline' }}>ลบการ์ด</span>
            </div>
          )}
        </div>
        <CardItem botId={botId} element={elements[currIndex]} onChange={handleEditCard} />
      </div>
    </>
  )
}

CarouselEditor.propTypes = {
  botId: PT.string.isRequired,
  message: PT.object,
  onMessageChange: PT.func,
  loading: PT.bool,
}

CarouselEditor.defaultProps = {
  message: {},
  onMessageChange: () => {},
}

export default CarouselEditor
