import { useState } from 'react'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import gql from 'graphql-tag'

// const ALL_BOTS = gql`
//   query allBotsWithSubscribe {
//     allBotsWithSubscribe {
//       id
//       title
//       picture_url
//       package_name
//       subscription_cost
//     }
//   }
// `

const ON_BOT_CHANGED = gql`
  subscription onBotChanged {
    onBotChanged {
      operation
      data {
        id
        business_type
        title
        category
        picture_url
      }
    }
  }
`

const ALL_BOTS = gql`
  query allBotsWithSubscribe {
    searchBot {
      data {
        id
        title
        picture_url
        business_type
        profile {
          title
          open_hours
          contact
          address
        }
        friend_count
        friend_limit
        sku
        subscription {
          title
          price
          status
          billing_period
          expired_in
        }
        billing_type
        modules
      }
      next
      total
    }
  }
`
export const useBotListData = () => {
  const [bots, setBots] = useState([])
  const [loading, setLoading] = useState(true)

  useQuery(ALL_BOTS, {
    fetchPolicy: 'no-cache',
    onError: (err) => console.error(err),
    onCompleted: ({ searchBot }) => {
      setLoading(false)
      setBots(searchBot.data)
    },
  })

  useSubscription(ON_BOT_CHANGED, {
    onSubscriptionData: ({ subscriptionData }) => {
      const {
        data: { onBotChanged = {} },
      } = subscriptionData
      const { data: newBot, operation } = onBotChanged
      console.log(newBot)
      const tmp = { ...newBot }
      delete tmp.doctype
      delete tmp.op
      let newBots = [...bots]
      if (operation === 'CREATE') {
        setBots([tmp, ...newBots])
      }
    },
  })

  return { data: bots, loading }
}

export default useBotListData
