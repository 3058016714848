1
import React, { useEffect, useState } from 'react'

import COVER_IMAGE from '../assets/alive/cover.png'
import bgBottom from '../assets/bgBottom.png'

import './Cover.css'
import { useForm } from 'react-hook-form'
import { stringify, toBase64 } from '@aiya/helper'

type Props = {
  onSignUp?: (data) => void
}

export const Cover = ({ onSignUp }: Props) => {
  const { register, handleSubmit, watch, errors } = useForm()
  const [isAis, setIsAis] = useState(false)
  const onSubmit = (data) => {
    if (onSignUp) {
      onSignUp(data)
    }
    // const q = toBase64(stringify(data))
  }

  useEffect(() => {
    if (window.location.host.includes('ais-')) {
      setIsAis(true)
    }
  }, [])

  return (
    <div className="page-container">
      <div className="cards">
        <div className="card">
          <div className="video-container">
            <img src={COVER_IMAGE} id="myCover" />
          </div>
          {/* <div className="video-container">
            <video autoPlay muted loop playsInline id="myVideo">
              <source src={COVER_IMAGE} type="video/mp4" />
            </video>
          </div> */}
        </div>
        <div className="card">
          <div className="form-container">
            <div>
              <div style={{ marginBottom: 15, textAlign: 'center' }}>
                <div className="title">
                  ให้ไลฟ์สด รับออเดอร์
                  <br />
                  เป็นเรื่องง่าย
                </div>
                {isAis && (
                  <div className="subtitle">
                     เปิดเบอร์ใหม่ AIS รับสิทธิพิเศษ
                    <br />
                    รับสิทธิ์พิเศษ ฟรีค่าบริการ 1 ปี
                  </div>
                )}
              </div>
              <div className="form-area">
                <input
                  type="text"
                  placeholder="ชื่อธุรกิจ"
                  name="business_name"
                  ref={register({ required: true })}
                />
                {errors.business_name && <span>* กรุณากรอกข้อมูล</span>}
                <input
                  type="text"
                  placeholder="ชื่อ"
                  name="first_name"
                  ref={register({ required: true })}
                />
                {errors.firstname && <span>* กรุณากรอกข้อมูล</span>}
                <input
                  type="text"
                  placeholder="นามสกุล"
                  name="last_name"
                  ref={register({ required: true })}
                />
                {errors.lastname && <span>* กรุณากรอกข้อมูล</span>}
                <input
                  type="text"
                  placeholder="เบอร์โทรศัพท์ ex. 0818880000"
                  name="phone"
                  ref={register({ required: true, pattern: /([0-9]{3})*([0-9]{3})*([0-9]{4})$/i })}
                />
                {errors.phone && errors.phone.type === 'required' && <span>* กรุณากรอกข้อมูล</span>}
                {errors.phone && errors.phone.type === 'pattern' && (
                  <span>* กรุณากรอกรูปแบบ 0818886666 หรือ 081-888-6666</span>
                )}
                <div className="button" onClick={handleSubmit(onSubmit)}>
                  เปิดใช้บริการ
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cover-bg-bottom">
        <img src={bgBottom} width="100%" />
      </div>
    </div>
  )
}

export default Cover
