export const NO_IMG =
  'https://s3.ap-southeast-1.amazonaws.com/dev.admin.aiya.ai/f0d5fcfb70/images/No_image_Gray.jpg'
export const DEFAULT_IMGMAP =
  'https://s3.ap-southeast-1.amazonaws.com/dev.admin.aiya.ai/f0d5fcfb70/images/No_image_Gray_1040.jpg'

const colors = {
  FB_THEME: '#0084ff',
  FB_BUBBLE: '#0099ff',
  LINE_THEME: '#00c300',
  LINE_BUBBLE: '#84e884',
  APP_THEME: '#1c70b0',
}

export const FB_BUBBLE_COLOR = { text: 'white', background: colors.FB_BUBBLE }
