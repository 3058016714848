import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'

const Btn = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  .child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover {
    ${({ hoverColor }) => (hoverColor ? `color: ${hoverColor}` : 'color: rgba(28, 112, 176, 1)')};
  }
  ${({ disabled }) =>
    disabled &&
    `
    color: #b7b7b7 !important;
    cursor: not-allowed !important;
  `}
`

const IconButton = ({ children, hoverColor, disabled, style, onClick }) => {
  return (
    <Btn style={style} onClick={onClick} hoverColor={hoverColor} disabled={disabled}>
      <span className="child">{children}</span>
    </Btn>
  )
}

IconButton.propTypes = {
  children: PT.node,
  disabled: PT.bool,
  onClick: PT.func,
  style: PT.object,
  hoverColor: PT.string,
}

IconButton.defaultProps = {
  onClick: () => {},
  style: {},
}

export default IconButton
