import React from 'react'

import DEMO from '../assets/screenshot/reservation.png'

import './Testimonial.css'

export const Testimonial = () => {
  return (
    <div className="testimonial">
      <div className="testimonial--title">CASE STUDY</div>
      <div className="testimonial--container">
        <div className="left">
          <img src={DEMO} />
        </div>
        <div className="right">
          <div className="title">Cool Smile Clinic</div>
          <div className="description">แชทบอท รับนัดหมายเข้ารับบริการทันตกรรม แบบหลายสาขา</div>
        </div>
      </div>
    </div>
  )
}

export default Testimonial
