import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import { LoadingOutlined } from '@ant-design/icons'

const Container = styled.span`
  transition: all 0.35s;
  display: inline-block;
  align-items: flex-start;
  overflow: visible;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  user-select: none;
  font-weight: ${({ type }) => (type === 'default' ? 400 : 600)};
  width: ${({ block }) => (block ? '100%' : 'auto')};
  ${({ theme }) => `
    background-color: ${theme.bg};
    color: ${theme.text};
    border: ${theme.border};
    &:hover {
      background-color: ${theme.hover.bg};
      color: ${theme.hover.text};
      border: ${theme.hover.border};
    }
    `}
  ${({ ghost }) =>
    !!ghost &&
    `
    background-color: transparent;
    color: ${ghost.color};
    border: 1px solid ${ghost.color};
    &:hover {
      background-color: transparent;
      color: ${ghost.hover};
      border: 1px solid ${ghost.hover};
    }
  `}
  ${({ loading }) => !!loading && `filter: opacity(50%);`}
  ${({ dimension }) => `
    height: ${dimension.height};
    padding: ${dimension.padding};
    line-height: ${dimension.lineHeight};
  `}
  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    background-color: #f5f5f5 !important;
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: #d9d9d9 !important;
  `}
`

export const Button = ({
  children,
  onClick,
  style,
  block,
  type,
  size,
  loading,
  icon,
  ghost,
  color,
  hoverColor,
  disabled,
}) => {
  const theme = {
    default: {
      bg: '#FFFFFF',
      text: '#676F84',
      border: '1px solid #B7B7B7',
      hover: {
        bg: '#FFFFFF',
        text: hoverColor || color || '#5799cc', // change text color
        border: `1px solid ${hoverColor || color || '#5799cc'}`, // change border color
      },
    },
    primary: {
      bg: color || '#1C70B0',
      text: '#FFFFFF',
      border: `1px solid ${color || '#1C70B0'}`,
      hover: {
        bg: hoverColor || '#5799cc', // change background color
        text: '#FFFFFF',
        border: `1px solid ${hoverColor || '#5799cc'}`, // change border color
      },
    },
    secondary: {
      bg: '#DBDBDB',
      text: '#676F84',
      border: '1px solid #DBDBDB',
      hover: {
        bg: '#DBDBDB',
        text: hoverColor || color || '#1C70B0', // change text color
        border: '1px solid #DBDBDB',
      },
    },
    danger: {
      bg: '#FF5C5C',
      text: '#FFFFFF',
      border: '1px solid #FF5C5C',
      hover: {
        bg: '#ff8181', // change background color
        text: '#FFFFFF',
        border: '1px solid #ff8181', // change border color
      },
    },
  }
  const ghostColor = {
    default: { color: '#676F84', hover: '#1C70B0' },
    primary: { color: '#1C70B0', hover: '#5799cc' },
    secondary: { color: '#676F84', hover: '#1C70B0' },
    danger: { color: '#FF5C5C', hover: '#ff8181' },
  }
  const dimension = {
    large: {
      padding: !!children ? '0px 16px' : '0px 8px',
      height: '40px',
      lineHeight: '37px',
    },
    default: {
      padding: !!children ? '0px 16px' : '0px 8px',
      height: '32px',
      lineHeight: '29px',
    },
    small: {
      padding: !!children ? '0px 8px' : '0px 4px',
      height: '24px',
      lineHeight: '21px',
    },
  }
  return (
    <Container
      onClick={() => !disabled && !loading && onClick()}
      style={style}
      block={block}
      theme={theme[type]}
      dimension={dimension[size]}
      loading={loading ? 1 : 0}
      ghost={ghost && ghostColor[type]}
      type={type}
      disabled={disabled}
    >
      <span style={{ marginRight: children && (loading || icon) ? 8 : 0 }}>
        {loading ? <LoadingOutlined /> : icon}
      </span>
      <span>{children}</span>
    </Container>
  )
}

Button.propTypes = {
  children: PT.oneOfType([PT.string, PT.node]),
  onClick: PT.func,
  style: PT.object,
  block: PT.bool,
  type: PT.oneOf(['default', 'primary', 'secondary', 'danger']),
  size: PT.oneOf(['large', 'small', 'default']),
  loading: PT.bool,
  ghost: PT.bool,
  icon: PT.node,
  color: PT.string,
  hoverColor: PT.string,
  disabled: PT.bool,
}

Button.defaultProps = {
  onClick: () => {},
  style: {},
  type: 'default',
  size: 'default',
  block: false,
  loading: false,
  ghost: false,
}

export default Button
