import React from 'react'
import './Hero.css'

export const Hero = ({ children }) => {
  return (
    <div className="ai-page--hero">
      <div className="ai-page--hero--container">
        <div className="ai-page--hero--content">{children}</div>
      </div>
    </div>
  )
}
