1
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

// import './aplus-lead-form.css'
import { useForm } from 'react-hook-form'

type Input = {
  name: string
  nickname: string
  school: string
  grade: string
  email: string
  phone: string
  line_id: string
  objective: string
  remark: string
  // enroll_type: string
  // budget: string
  policy_accepted: boolean
}

type Props = {
  onSubmit?: (data: Input) => void
}

export const AplusLeadForm = ({ onSubmit }: Props) => {
  // const [enrollType, setEnrollType] = useState('แฟรนไชส์หลัก')
  const { register, handleSubmit, watch, errors, setValue, getValues } = useForm({
    defaultValues: {
      objective: '',
      // enroll_type: 'แฟรนไชส์รอง',
      // enroll_type: 'แฟรนไชส์หลัก',2
    } as Input,
  })

  const watchObjective = watch('objective')

  return (
    <StyledApp>
      <div className="lead--page-container">
        <div className="lead--cards">
          <div className="lead--card" style={{ flex: 3 }}>
            <div className="lead--video-container">
              <img
                src="https://drive.aiya.ai/b/3ac51636f7/images/4b7448633408cc8bf7907bf76814bb8c.jpg"
                id="leadCover"
              />
            </div>
          </div>
          <div className="lead--card" style={{ flex: 2 }}>
            <div className="lead--form-container">
              <div>
                <div style={{ marginBottom: 15, textAlign: 'center' }}>
                  <div className="lead--title">เก่งอังกฤษ กับ APlus</div>
                </div>
                {/* <input type="hidden" name="budget" ref={register} />
              <input type="hidden" name="province" ref={register} />
              <input type="hidden" name="city" ref={register} />
              <input type="hidden" name="tambon" ref={register} />
              <input type="hidden" name="shop" ref={register} /> */}
                <div className="lead--form-area">
                  <input
                    type="text"
                    placeholder="ชื่อ - นามสกุล"
                    name="name"
                    ref={register({ required: true })}
                  />
                  {errors.name && <span className="error">* กรุณากรอกข้อมูล</span>}

                  <input
                    type="text"
                    placeholder="ชื่อเล่น"
                    name="nickname"
                    ref={register({ required: true })}
                  />
                  {errors.nickname && <span className="error">* กรุณากรอกข้อมูล</span>}

                  <input
                    type="text"
                    placeholder="โรงเรียน"
                    name="school"
                    ref={register({ required: true })}
                  />
                  {errors.school && <span className="error">* กรุณากรอกข้อมูล</span>}

                  <input
                    type="text"
                    placeholder="ระดับชั้น"
                    name="grade"
                    ref={register({ required: true })}
                  />
                  {errors.grade && <span className="error">* กรุณากรอกข้อมูล</span>}

                  <input
                    type="text"
                    placeholder="อีเมล"
                    name="email"
                    ref={register({ required: true })}
                  />
                  {errors.email && <span className="error">* กรุณากรอกข้อมูล</span>}
                  <input
                    type="text"
                    placeholder="ไลน์ไอดี"
                    name="line_id"
                    ref={register({ required: true })}
                  />
                  {errors.line_id && <span className="error">* กรุณากรอกข้อมูล</span>}
                  <input
                    type="text"
                    placeholder="เบอร์โทรศัพท์ ex. 0818880000"
                    name="phone"
                    ref={register({
                      required: true,
                      pattern: /([0-9]{3})*([0-9]{3})*([0-9]{4})$/i,
                    })}
                  />
                  {errors.phone && errors.phone.type === 'required' && (
                    <span className="error">* กรุณากรอกข้อมูล</span>
                  )}
                  {errors.phone && errors.phone.type === 'pattern' && (
                    <span className="error">* กรุณากรอกรูปแบบ 0818886666 หรือ 081-888-6666</span>
                  )}
                  <div style={{ height: 15 }}></div>
                  <label>เป้าหมายในการเรียน</label>
                  <select name="objective" ref={register({ required: true })}>
                    <option value="">--- กรุณาเลือก ---</option>
                    <option value="หลักสูตรนานาชาติ">หลักสูตรนานาชาติ</option>
                    <option value="หลักสูตรภาคไทย">หลักสูตรภาคไทย</option>
                    <option value="เรียนต่างประเทศ">เรียนต่างประเทศ</option>
                    <option value="พัฒนาทักษะ">พัฒนาทักษะ</option>
                    <option value="อื่นๆ">อื่นๆ</option>
                  </select>
                  {errors.objective && <span className="error">* กรุณากรอกข้อมูล</span>}
                  {(watchObjective === 'หลักสูตรนานาชาติ' ||
                    watchObjective === 'หลักสูตรภาคไทย') && (
                    <>
                      <input
                        type="text"
                        placeholder="กรุณาระบุคณะที่ต้องเข้าเรียน"
                        name="remark"
                        ref={register({ required: true })}
                      />
                      {errors.remark && <span className="error">* กรุณากรอกข้อมูล</span>}
                    </>
                  )}

                  {watchObjective === 'เรียนต่างประเทศ' && (
                    <>
                      <input
                        type="text"
                        placeholder="กรุณาระบุประเทศที่ต้องการไปเรียน"
                        name="remark"
                        ref={register({ required: true })}
                      />
                      {errors.remark && <span className="error">* กรุณากรอกข้อมูล</span>}
                    </>
                  )}

                  {watchObjective === 'พัฒนาทักษะ' && (
                    <>
                      <input
                        type="text"
                        placeholder="ระบุทักษะที่ต้องการพัฒนา เช่น ฟัง/พูด/อ่าน/เขียน"
                        name="remark"
                        ref={register({ required: true })}
                      />
                      {errors.remark && <span className="error">* กรุณากรอกข้อมูล</span>}
                    </>
                  )}

                  {watchObjective === 'อื่นๆ' && (
                    <>
                      <input
                        type="text"
                        placeholder="โปรดระบุ"
                        name="remark"
                        ref={register({ required: true })}
                      />
                      {errors.remark && <span className="error">* กรุณากรอกข้อมูล</span>}
                    </>
                  )}

                  <div style={{ marginTop: 15 }}>
                    <input
                      name="policy_accepted"
                      type="checkbox"
                      ref={register({ required: true })}
                    />{' '}
                    <span
                      style={{ fontSize: 14, cursor: 'pointer' }}
                      onClick={() => {
                        setValue('policy_accepted', !getValues('policy_accepted'))
                      }}
                    >
                      ยอมรับนโยบายข้อมูลส่วนบุคคล และข้อกําหนด
                    </span>
                    {errors.policy_accepted && (
                      <div className="error">You have to accept terms & conditions</div>
                    )}
                  </div>
                  <div className="button" onClick={onSubmit && handleSubmit(onSubmit)}>
                    ลงทะเบียน
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lead--cover-bg-bottom">{/* <img src={bgBottom} width="100%" /> */}</div>
      </div>
    </StyledApp>
  )
}

const StyledApp = styled.div`
  .lead--page-container {
    /* background: transparent
    linear-gradient(120deg, #465599 0%, #456ca2 31%, #7ba18f 100%, #7ba18f 100%) 0% 0% no-repeat
    padding-box; */
    width: 100%;
    overflow: hidden;
    padding-top: 40px;
  }

  .lead--cards {
    display: flex;
    font-family: 'Prompt', sans-serif;
    width: 100%;
    /* height: 500px; */
    opacity: 1;
    color: #662e90;
    flex-wrap: wrap;
    overflow: hidden;
    max-width: 1440px;
    margin: auto;
    padding-bottom: 30px;
    /* padding: 60px; */
  }

  .lead--card {
    /* background-color: dodgerblue; */
    color: #662e90;
    /* padding: 1rem; */
    /* height: 500px; */
    flex: 1;
    min-width: 300px;
  }

  #leadCover {
    /* right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%; */
    /* max-height: 450px; */
    width: 100%;
    height: 100%;
    /* margin: auto; */
    outline: none;
    border: none;
    box-shadow: none;
    background-color: transparent;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
  }

  .lead--video-container {
    width: 100%;
    /* height: 100%; */
    display: flex;
    justify-content: center;
    /* align-items: center; */
    outline: none;
    border: none;
    background-color: transparent;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
    padding: 0px 20px;
  }

  .lead--form-container {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    max-width: 320px;
    margin: auto;
    height: 100%;
  }

  .lead--title {
    font-size: x-large;
    color: #662e90;
  }

  .lead--subtitle {
    font-size: medium;
    font-weight: 200;
    text-align: center;
  }

  .lead--form-area input[type='text'] {
    margin-top: 15px;
    width: 100%;
    /* border: none; */
    border: 1px solid #d0d0d0;
    border-radius: 4px;
    background-color: transparent;
    outline: none;
    height: 45px;
    color: #662e90;
    /* opacity: 0.27; */
    padding-left: 15px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
  }

  textarea,
  input ::placeholder {
    color: #662e90;
    opacity: 0.6;
    font-weight: 100;
  }

  .lead--form-area input[type='checkbox'] {
    /* border: none; */
    border: 1px solid #d0d0d0;
    border-radius: 4px;
    background-color: transparent;
    outline: none;
    color: #662e90;
    /* opacity: 0.27; */
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
  }

  .lead--form-area .button {
    display: inline-block;
    overflow: visible;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    user-select: none;
    background-color: #662e90;
    border: none;
    color: #ffffff;
    height: 40px;
    width: 100%;
    line-height: 30px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
    margin-top: 15px;
    border-color: #4475e2;
  }

  .lead--form-area select {
    border: 1px solid #d0d0d0;
    border-radius: 4px;
    background-color: transparent;
    outline: none;
    height: 45px;
    color: #662e90;
    /* opacity: 0.27; */
    padding-left: 15px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    font-size: 14px;
    font-weight: 300;
  }

  .lead--form-area label {
    color: #662e90;
    font-size: small;
    font-weight: 300;
  }

  .lead--form-area ::placeholder {
    color: #662e90;
    opacity: 0.6;
    font-weight: 100;
  }

  .lead--enroll-form {
    font-size: 14px;
    margin-top: 18px;
  }

  .lead--cover-bg-bottom {
    margin-bottom: -10px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 667px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .lead--page-container {
      padding-top: 0px;
    }

    .lead--cards {
      display: flex;
      font-family: 'Prompt', sans-serif;
      opacity: 1;
      color: white;
    }

    .lead--form-container {
      margin-top: 40px;
    }

    .lead--cover-bg-bottom {
      margin-bottom: -1px;
    }

    .lead--video-container {
      padding: 0px;
    }
  }

  #checkboxes label {
    float: left;
  }

  #checkboxes ul {
    margin-top: 5px;
    list-style: none;
    float: left;
    padding: 0px;
    cursor: pointer;
    width: 100%;
  }

  .error {
    font-size: 12px;
    color: red;
    opacity: 0.6;
  }
`
