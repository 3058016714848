import React from 'react'

import LOGO_ACRM from '../assets/Logo/logo-alive.png'
import HIGHLIGHT from '../assets/screenshot/highlight.png'

import './WhyUs.css'

export const WhyUs = () => {
  return (
    <div className="why-us">
      <div className="logo">
        <img src={LOGO_ACRM} />
      </div>
      <div className="subtitle">Facebook LIVE Management</div>
      <div className="title">โปรแกรมบริหารการไลฟ์สด</div>
      <div className="description">
        <div>สำหรับแม่ค้ายุคใหม่ ที่ทำให้การไลฟ์เป็นเรื่องง่าย</div>
        <div>ด้วยเทคโนโลยี AI อัจฉริยะ</div>
      </div>
      <div className="highlight">
        <img src={HIGHLIGHT} width="100%" />
      </div>
    </div>
  )
}

export default WhyUs
