import { isObject } from 'util'

export const jsonParse = (text: string | any, _default: any = {}) => {
  try {
    if (text) {
      return JSON.parse(text)
    }
    return _default
  } catch (error) {
    return _default
  }
}

export const stringify = (obj: any) => {
  return isObject(obj) ? JSON.stringify(obj) : obj
}

export const truncate = function (fullStr, strLen = 20, separator = '...') {
  if (fullStr.length <= strLen) return fullStr

  var sepLen = separator.length
  var charsToShow = strLen - sepLen
  var frontChars = Math.ceil(charsToShow / 2)
  var backChars = Math.floor(charsToShow / 2)

  return fullStr.substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars)
}

export const toBase64 = (s: string) => {
  return Buffer.from(s).toString('base64')
}

export const decodeBase64 = (s: string) => {
  return Buffer.from(s, 'base64').toString('utf-8')
}
