import React from 'react'
import { Collapse, Input, Select, Modal } from 'antd'
import Icon from '@ant-design/icons'

import ButtonItem from './ButtonItem'
import ButtonForm from './ButtonForm'
import InputField from '../../../components/InputField'
import { KeyValueInput } from '../../../../KeyValueInput'
import { JSONInput } from '../../../../JSONInput'

const DynamicCarouselEditor = ({ messageObj, onChange, loading }) => {
  const [input, setInput] = React.useState({
    message: messageObj.message,
    request: messageObj.request,
    mapping: messageObj.mapping,
    element: messageObj.element,
    child_mapping: messageObj.child_mapping,
    child_element: messageObj.child_element,
    payload: messageObj.payload,
  })
  const [modalState, setModal] = React.useState(-1)
  const [btnInput, setBtnInput] = React.useState({})

  const oldElements = (((input.message || {}).attachment || {}).payload || {}).elements || []
  const buttons = (oldElements[0] || {}).buttons || []

  React.useEffect(() => {
    setInput({
      message: messageObj.message,
      request: messageObj.request,
      mapping: messageObj.mapping,
      element: messageObj.element,
      child_mapping: messageObj.child_mapping,
      child_element: messageObj.child_element,
      payload: messageObj.payload,
    })
  }, [messageObj])

  const inputChange = (item) => {
    setInput({ ...input, ...item })
  }

  const elementChange = (item, isUpdate: boolean = false) => {
    const newMessage = {
      attachment: {
        type: 'template',
        payload: {
          template_type: 'generic',
          elements: [
            {
              ...oldElements[0],
              ...item,
            },
          ],
        },
      },
    }
    inputChange({ message: newMessage })
    isUpdate && updateObj({ message: newMessage })
  }

  const inputBtnChange = (item) => {
    setBtnInput({ ...btnInput, ...item })
  }

  const handleMethodChange = (val) => {
    inputChange({ request: { ...input.request, method: val, variables: {} } })
    updateObj({ request: { ...input.request, method: val, variables: {} } })
  }

  const updateObj = (override = {}) => {
    onChange({ ...input, ...override })
  }

  const handleButtonEditClick = (index) => {
    setModal(index)
    const btnData = buttons[index]
    setBtnInput(btnData)
  }

  const handleAddBtn = () => {
    const newBtn = {
      type: 'postback',
      title: 'Start Chatting',
      payload: 'Get Started',
    }
    elementChange({ buttons: [...buttons, newBtn] }, true)
  }

  const handleEditBtn = () => {
    let tmp = [...buttons]
    tmp[modalState] = btnInput
    elementChange({ buttons: tmp }, true)
    setBtnInput({})
    setModal(-1)
  }

  const handleDelBtn = (index) => {
    let tmp = [...buttons]
    tmp.splice(index, 1)
    elementChange({ buttons: tmp }, true)
  }

  return (
    <div>
      <Collapse accordion bordered={false}>
        <Collapse.Panel header="Request" key="requeset">
          <InputField title="URI">
            <Input
              addonBefore={
                <Select
                  style={{ width: '80px' }}
                  value={(input.request || {}).method}
                  onChange={(val) => handleMethodChange(val)}
                  loading={loading}
                >
                  <Select.Option key="GET" value="GET">
                    GET
                  </Select.Option>
                  <Select.Option key="POST" value="POST">
                    POST
                  </Select.Option>
                </Select>
              }
              value={(input.request || {}).uri}
              onChange={(e) => inputChange({ request: { ...input.request, uri: e.target.value } })}
              onBlur={() => updateObj()}
              onPressEnter={() => updateObj()}
              disabled={loading}
              placeholder="https://www.example.com"
            />
          </InputField>
          <InputField title="Headers">
            <KeyValueInput
              data={(input.request || {}).headers}
              onChange={(val) => inputChange({ request: { ...input.request, headers: val } })}
              onSubmit={(val) => updateObj({ request: { ...input.request, headers: val } })}
              loading={loading}
            />
          </InputField>
          {(input.request || {}).method === 'GET' && (
            <InputField title="Params">
              <KeyValueInput
                loading={loading}
                data={(input.request || {}).variables}
                onChange={(val) => inputChange({ request: { ...input.request, variables: val } })}
                onSubmit={(val) => updateObj({ request: { ...input.request, variables: val } })}
              />
            </InputField>
          )}
          {(input.request || {}).method === 'POST' && (
            <InputField title="Body">
              <JSONInput
                data={(input.request || {}).variables}
                onChange={(val) => updateObj({ request: { ...input.request, variables: val } })}
                loading={loading}
              />
            </InputField>
          )}
        </Collapse.Panel>
        <Collapse.Panel header="Mapping & Element" key="mapping_element">
          <InputField title="Mapping">
            <Input
              value={input.mapping}
              onChange={(e) => inputChange({ mapping: e.target.value })}
              disabled={loading}
              onBlur={() => updateObj()}
              onPressEnter={() => updateObj()}
            />
          </InputField>
          <InputField title="Element">
            <Input
              value={input.element}
              onChange={(e) => inputChange({ element: e.target.value })}
              disabled={loading}
              onBlur={() => updateObj()}
              onPressEnter={() => updateObj()}
            />
          </InputField>
          <InputField title="Child Mapping">
            <Input
              value={input.child_mapping}
              onChange={(e) => inputChange({ child_mapping: e.target.value })}
              disabled={loading}
              onBlur={() => updateObj()}
              onPressEnter={() => updateObj()}
            />
          </InputField>
          <InputField title="Child Element">
            <Input
              value={input.child_element}
              onChange={(e) => inputChange({ child_element: e.target.value })}
              disabled={loading}
              onBlur={() => updateObj()}
              onPressEnter={() => updateObj()}
            />
          </InputField>
        </Collapse.Panel>

        <div style={{ padding: '8px 16px' }}>
          <InputField title="หัวข้อ">
            <Input
              value={(oldElements[0] || {}).title}
              onChange={(e) => elementChange({ title: e.target.value || undefined })}
              onBlur={() => updateObj()}
              onPressEnter={() => updateObj()}
            />
          </InputField>
          <InputField title="คำอธิบาย">
            <Input
              value={(oldElements[0] || {}).subtitle}
              onChange={(e) => elementChange({ subtitle: e.target.value || undefined })}
              onBlur={() => updateObj()}
              onPressEnter={() => updateObj()}
            />
          </InputField>
          <InputField title="รูปภาพ">
            <Input
              value={(oldElements[0] || {}).image_url}
              onChange={(e) => elementChange({ image_url: e.target.value || undefined })}
              onBlur={() => updateObj()}
              onPressEnter={() => updateObj()}
            />
          </InputField>
          <InputField title={`ปุ่ม (${((oldElements[0] || {}).buttons || []).length} / 3)`}>
            <div
              style={{
                border: '1px solid rgba(0, 0, 0, 0.1)',
                borderRadius: 4,
                overflow: 'hidden',
              }}
            >
              {buttons.map((btn, i) => (
                <ButtonItem
                  key={i}
                  data={btn}
                  onEdit={() => handleButtonEditClick(i)}
                  onDelete={buttons.length > 1 ? () => handleDelBtn(i) : undefined}
                />
              ))}
            </div>
            {buttons.length < 3 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  fontWeight: 500,
                  marginTop: 8,
                }}
                onClick={handleAddBtn}
              >
                <Icon style={{ marginRight: 4 }} type="plus-circle" />
                <span style={{ textDecoration: 'underline' }}>เพิ่มปุ่ม</span>
              </div>
            )}
          </InputField>

          <Modal
            title="แก้ไขข้อมูลปุ่ม"
            visible={modalState !== -1}
            onCancel={() => setModal(-1)}
            onOk={handleEditBtn}
          >
            <ButtonForm input={btnInput} inputChange={inputBtnChange} />
          </Modal>
        </div>
      </Collapse>
    </div>
  )
}

export default DynamicCarouselEditor
