import React from 'react'
import PT from 'prop-types'
import { Select } from 'antd'

import Button from '../../components/Button'
import IconButton from '../../components/IconButton'
import { defaultLineQR } from '../../constants/defaultMsg'
import { lineQuickReplyType } from '../../constants/msgType'

import MessageForm from './Form/MessageForm'
import PostbackForm from './Form/PostbackForm'
import LocationForm from './Form/LocationForm'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'

const MAX_ITEMS = 13
const ITEM_SIZE = 360

export const LineQuickReplyEditor = ({ items, onChange }) => {
  const handleTypeChange = (type, index) => {
    const newItem = defaultLineQR[type]
    updateItem(newItem, index)
  }

  const handleActionChange = (action, index) => {
    const newItem = {
      ...items[index],
      action: {
        ...items[index].action,
        ...action,
      },
    }
    updateItem(newItem, index)
  }

  const addItem = () => {
    const newItem = defaultLineQR.message
    const tmp = [...items, newItem]
    onChange(tmp)
  }

  const updateItem = (qr, indexToUpdate) => {
    let tmp = [...items]
    tmp[indexToUpdate] = qr
    onChange(tmp)
  }

  const removeItem = (indexToRemove) => {
    let tmp = [...items]
    tmp.splice(indexToRemove, 1)
    onChange(tmp)
  }

  return (
    <div>
      <div>
        Quick Reply ( {items.length} / {MAX_ITEMS} )
      </div>
      {items.map((qr, i) => {
        const qrAction = (qr || {}).action || {}
        return (
          <div
            key={i}
            style={{
              width: ITEM_SIZE,
              display: 'flex',
              flexDirection: 'column',
              margin: '8px auto',
              border: '1px solid rgba(0, 0, 0, 0.15)',
              borderRadius: 4,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '4px 8px',
                borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
              }}
            >
              <span>
                <span style={{ marginRight: 8 }}>Type:</span>
                <Select
                  size="small"
                  style={{ minWidth: 120 }}
                  value={((qr || {}).action || {}).type}
                  onChange={(val) => handleTypeChange(val, i)}
                >
                  {Object.keys(lineQuickReplyType).map((key) => (
                    <Select.Option key={lineQuickReplyType[key]} value={lineQuickReplyType[key]}>
                      {lineQuickReplyType[key]}
                    </Select.Option>
                  ))}
                </Select>
              </span>
              <IconButton hoverColor="red" onClick={() => removeItem(i)}>
                <CloseOutlined />
              </IconButton>
            </div>
            <div style={{ padding: '12px 24px' }}>
              {qrAction.type === lineQuickReplyType.MESSAGE && (
                <MessageForm
                  qrAction={qrAction}
                  onChange={(action) => handleActionChange(action, i)}
                />
              )}
              {qrAction.type === lineQuickReplyType.POSTBACK && (
                <PostbackForm
                  qrAction={qrAction}
                  onChange={(action) => handleActionChange(action, i)}
                />
              )}
              {qrAction.type === lineQuickReplyType.LOCATION && (
                <LocationForm
                  qrAction={qrAction}
                  onChange={(action) => handleActionChange(action, i)}
                />
              )}
            </div>
            <div
              style={{
                fontSize: '0.85em',
                borderTop: '1px solid rgba(0, 0, 0, 0.15)',
                padding: '0 8px',
              }}
            >
              <span style={{ fontWeight: 600, color: 'rgba(28, 112, 176, 1)' }}>{i + 1}</span>
            </div>
          </div>
        )
      })}
      {items.length < MAX_ITEMS && (
        <div style={{ textAlign: 'center' }}>
          <Button icon={<PlusOutlined />} onClick={addItem}>
            เพิ่ม Quick Reply
          </Button>
        </div>
      )}
    </div>
  )
}

LineQuickReplyEditor.propTypes = {
  items: PT.arrayOf(
    PT.shape({
      type: PT.oneOf(['action']).isRequired,
      imageUrl: PT.string,
      action: PT.shape({
        type: PT.oneOf(Object.keys(lineQuickReplyType).map((key) => lineQuickReplyType[key]))
          .isRequired,
        label: PT.string.isRequired,
      }).isRequired,
    })
  ),
  onChange: PT.func,
}

LineQuickReplyEditor.defaultProps = {
  items: [],
  onChange: () => {},
}

export default LineQuickReplyEditor
