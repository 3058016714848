import { isString } from 'util'
import { stringify } from './str'

const Handlebars = require('handlebars')
const HandlebarsIntl = require('handlebars-intl')
HandlebarsIntl.registerWith(Handlebars)
Handlebars.registerHelper('math', function (
  lvalue: string,
  operator: string,
  rvalue: string,
  options: any
) {
  const _lvalue = parseFloat(lvalue)
  const _rvalue = parseFloat(rvalue)
  const calc: any = {
    '+': _lvalue + _rvalue,
    '-': _lvalue - _rvalue,
    '*': _lvalue * _rvalue,
    '/': _lvalue / _rvalue,
    '%': _lvalue % _rvalue,
  }

  return calc[operator]
})
//@ts-ignore
Handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {
  switch (operator) {
    case '==':
      //@ts-ignore
      return v1 == v2 ? options.fn(this) : options.inverse(this)
    case '===':
      //@ts-ignore
      return v1 === v2 ? options.fn(this) : options.inverse(this)
    case '!=':
      //@ts-ignore
      return v1 != v2 ? options.fn(this) : options.inverse(this)
    case '!==':
      //@ts-ignore
      return v1 !== v2 ? options.fn(this) : options.inverse(this)
    case '<':
      //@ts-ignore
      return v1 < v2 ? options.fn(this) : options.inverse(this)
    case '<=':
      //@ts-ignore
      return v1 <= v2 ? options.fn(this) : options.inverse(this)
    case '>':
      //@ts-ignore
      return v1 > v2 ? options.fn(this) : options.inverse(this)
    case '>=':
      //@ts-ignore
      return v1 >= v2 ? options.fn(this) : options.inverse(this)
    case '&&':
      //@ts-ignore
      return v1 && v2 ? options.fn(this) : options.inverse(this)
    case '||':
      //@ts-ignore
      return v1 || v2 ? options.fn(this) : options.inverse(this)
    default:
      //@ts-ignore
      return options.inverse(this)
  }
})

var intlData = {
  locales: 'th-TH',
  formats: {
    date: {
      long: {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      },
    },
    time: {
      hhmm: {
        hour: 'numeric',
        minute: 'numeric',
      },
    },
  },
}

export const render = (source: any, vars: Object) => {
  if (!isString(source)) {
    source = stringify(source)
  }
  // console.log(source)

  var template = Handlebars.compile(source)
  return template(vars, {
    data: { intl: intlData },
  })
}
