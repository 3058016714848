export function getImageResolution(imageUrl): Promise<{ width: number; height: number }> {
  return new Promise(function (resolve, reject) {
    var img = new Image()
    img.onload = function () {
      // @ts-ignore
      resolve({ width: this.width, height: this.height })
    }
    img.onerror = function (err) {
      reject(err)
    }
    img.src = imageUrl
  })
}
