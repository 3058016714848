import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'

import NumberRenderer from './NumberRenderer'

const Bar = styled.div`
  height: 100%;
  position: absolute;
  color: white;
  background-color: rgba(28, 112, 176, 1);
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0px 12px;
  transition: all 0.35s;
  ${({ percent }) => `
    width: ${percent || 100}%;
  `}
`

export const ProgressBar = ({ max, value, style }) => {
  return (
    <div
      style={{ position: 'relative', overflow: 'hidden', height: 24, borderRadius: 12, ...style }}
    >
      <Bar style={{ backgroundColor: 'rgba(208, 208, 208, 1)' }}></Bar>
      {value !== 0 && max !== 0 && <Bar percent={(value / max) * 100}></Bar>}
      <div
        style={{
          color: 'white',
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <NumberRenderer value={value} style={{ marginRight: 4 }} />
        <span>({Math.floor(max !== 0 ? (value / max) * 100 : 0)}%)</span>
      </div>
    </div>
  )
}

ProgressBar.propTypes = {
  value: PT.number.isRequired,
  max: PT.number.isRequired,
  style: PT.object,
}

export default ProgressBar
