import React from 'react'
import PT from 'prop-types'
import { Input, Select } from 'antd'
import InputField from '../../../components/InputField'

const ButtonForm = ({ input, inputChange }) => {
  const onTypeChange = (type) => {
    if (type === 'postback') {
      inputChange({
        type,
        url: undefined,
      })
    } else if (type === 'web_url') {
      inputChange({
        type,
        payload: undefined,
      })
    } else if (type === 'phone_number') {
      inputChange({
        type,
        payload: undefined,
      })
    }
  }

  return (
    <div>
      <InputField title="ชื่อปุ่ม">
        <Input value={input.title} onChange={(e) => inputChange({ title: e.target.value })} />
      </InputField>
      <InputField title="ประเภท">
        <Select style={{ width: '100%' }} value={input.type} onChange={(val) => onTypeChange(val)}>
          <Select.Option key="postback" value="postback">
            Postback
          </Select.Option>
          <Select.Option key="web_url" value="web_url">
            Link
          </Select.Option>
          <Select.Option key="phone_number" value="phone_number">
            Phone Number
          </Select.Option>
        </Select>
      </InputField>
      {input.type === 'postback' && (
        <InputField title="ข้อมูลที่จะส่ง">
          <Input value={input.payload} onChange={(e) => inputChange({ payload: e.target.value })} />
        </InputField>
      )}
      {input.type === 'web_url' && (
        <InputField title="ลิงค์">
          <Input value={input.url} onChange={(e) => inputChange({ url: e.target.value })} />
        </InputField>
      )}
      {input.type === 'phone_number' && (
        <InputField title="เบอร์ติดต่อ">
          <Input value={input.payload} onChange={(e) => inputChange({ payload: e.target.value })} />
        </InputField>
      )}
    </div>
  )
}

ButtonForm.propTypes = {
  input: PT.object,
  inputChange: PT.func,
}

ButtonForm.defaulProps = {
  inputChange: () => {},
}

export default ButtonForm
