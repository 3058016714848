import React from 'react'
import { Empty } from 'antd'

export const NoData = ({ description = '' }) => {
  return (
    <div
      style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, pointerEvents: 'none' }}
    >
      <Empty
        description={description || false}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    </div>
  )
}

export default NoData
