import React from 'react'
import PT from 'prop-types'
import { Input } from 'antd'

import InputField from '../../../components/InputField'

const LocationForm = ({ qrAction, onChange }) => {
  const [input, setInput] = React.useState(qrAction)

  React.useEffect(() => {
    setInput(qrAction)
  }, [qrAction])

  const inputChange = (item) => {
    setInput({ ...input, ...item })
  }

  const handleActionChange = () => {
    onChange(input)
  }

  return (
    <>
      <InputField title="ชื่อปุ่ม">
        <Input
          value={input.label}
          onChange={(e) => inputChange({ label: e.target.value })}
          onBlur={handleActionChange}
          onPressEnter={handleActionChange}
          maxLength={20}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <small style={{ fontWeight: 500, color: '#b7b7b7' }}>
            {(input.label || '').length} / 20
          </small>
        </div>
      </InputField>
    </>
  )
}

LocationForm.propTypes = {
  qrAction: PT.object,
  onChange: PT.func,
}

LocationForm.defaultProps = {
  onChange: () => {},
}

export default LocationForm
