import React from 'react'

export interface DividerProps {
  children?: any
  style?: any
  containerStyle?: any
  lineColor?: any
  margin?: number
}

export const Divider = ({ children, style, containerStyle, lineColor, margin }: DividerProps) => {
  return (
    <div
      style={{
        width: '100%',
        position: 'relative',
        marginTop: margin || 16,
        marginBottom: margin || 16,
        ...containerStyle,
      }}
    >
      <span
        style={{
          backgroundColor: 'white',
          paddingRight: '16px',
          color: 'rgba(0, 0, 0, 0.25)',
          ...style,
        }}
      >
        {children}
      </span>
      <div
        style={{
          width: '100%',
          position: 'absolute',
          top: '50%',
          zIndex: -1,
          transform: 'translateY(-50%)',
          height: 1,
          backgroundColor: lineColor || 'rgba(0, 0, 0, 0.15)',
        }}
      />
    </div>
  )
}

export default Divider
