import React, { useState } from 'react'
import './PriceTable.css'

import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

// import './Details.scss'

const Container = styled.div`
  width: 100%;
  max-width: 1600px;
  background: #ffffff;
  margin: -80px auto 50px auto;
  position: relative;
  padding: 50px 0 50px 0;
`
const MobileTab = styled.div`
  width: 100px;
  background: ${(props) => props.bg || 'white'};
  border-radius: 10px;
  margin-left: -20px;
  z-index: ${(props) => props.z || -1};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.color || 'white'};
`
const MobileTabTitle = styled.p`
  transform: rotate(270deg);
  font-size: 24px;
  padding-top: 20px;
`

const CardHeader = styled.div`
  border-bottom: 1px solid #00000029;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 0em;
  height: 140px;
  color: ${(props) => props.color || 'inherit'};
  background: ${(props) => props.bg || 'transparent'};
  padding-top: 50px;
`
const CardBody = styled.div`
  border-radius: 0 0 10px 10px;
  padding: 10px;
  line-height: 1.1em;
  font-size: 24px;
  text-align: center;
  color: ${(props) => props.color || 'inherit'};
  background: ${(props) => props.bg || 'transparent'};
`
const TextTitleBody = styled.p`
  margin-top: 30px;
  font-size: 16px;
`
const TextBody = styled.p`
  margin-top: 30px;
  font-size: 20px;
  margin: 10;
`
const MobileTextBody = styled.p`
  font-size: 20px;
  margin: 10;
`
const MobileDescBody = styled.p`
  font-size: 14px;
  margin: 10;
  margin-top: 30px;
`
const TextPriceHeader = styled.p`
  font-size: 18px;
  /* margin-top: 20px; */
  text-decoration: line-through;
`
const TextPromotionHeader = styled.p`
  font-size: 24px;
  margin-top: 10px;
  text-align: center;
  color: yellow;
`
const TextTitleHeader = styled.p`
  font-size: 40px;
`
const CardContainer = styled.div`
  position: relative;
  box-shadow: 0px 0px 5px #00000029;
  width: ${(props) => props.width || '200px'};
  border-radius: 10px;
  overflow: hidden;
  margin: ${(props) => (props.noMargin ? '0px' : '6px')};
  background: #f8f7f8;
  color: ${(props) => props.color || '#0095FF'};
  z-index: 20;
`

const packages = [
  {
    id: 0,
    containerColor: '#8D8D8D',
    headerColor: '#FFFFFF',
    headerBg: '#DCDCDC',
    bodyBg: '#FFFFFF',
    package: 'FREE',
    price: '',
    promotion: '',
    value: [
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      'ไม่จำกัดครั้ง',
      '-',
      '10 รายการ',
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      '-',
      '-',
      '-',
    ],
  },
  {
    id: 1,
    containerColor: '#527EC6',
    headerColor: 'white',
    headerBg: '#527EC6',
    bodyBg: '#FFFFFF',
    package: 'BASIC',
    price: '990 บ/เดือน',
    promotion: '299 บ/เดือน',
    value: [
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      'ไม่จำกัดครั้ง',
      '5 ครั้ง',
      '100 รายการ',
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      '-',
      '-',
      '5 ครั้ง',
    ],
  },
  {
    id: 2,
    containerColor: 'white',
    headerBg: '#3166BC',
    bodyBg: '#527EC6',
    package: 'PRO',
    price: '1,290 บ/เดือน',
    promotion: '599 บ/เดือน',
    value: [
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      'ไม่จำกัดครั้ง',
      'ไม่จำกัดครั้ง',
      '500 รายการ',
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      <FontAwesomeIcon className="social" icon={faCheckCircle} />,
      '10 ครั้ง',
    ],
  },
  // {
  //   id: 1,
  //   containerColor: '#527EC6',
  //   headerColor: 'white',
  //   headerBg: '#527EC6',
  //   bodyBg: '#FFFFFF',
  //   package: 'PRO',
  //   price: '฿990 mo',
  //   promotion: '',
  //   value: [
  //     <FontAwesomeIcon className="social" icon={faCheckCircle} />,
  //     'ไม่จำกัดครั้ง',
  //     '5 ครั้ง',
  //     '100 รายการ',
  //     <FontAwesomeIcon className="social" icon={faCheckCircle} />,
  //     <FontAwesomeIcon className="social" icon={faCheckCircle} />,
  //     <FontAwesomeIcon className="social" icon={faCheckCircle} />,
  //     <FontAwesomeIcon className="social" icon={faCheckCircle} />,
  //     <FontAwesomeIcon className="social" icon={faCheckCircle} />,
  //     '-',
  //     '-',
  //     '5 ครั้ง',
  //   ],
  // },
]

const dataKey = [
  '1. ระบบจัดการคำสั่งซื้ออัตโนมัติ',
  '2. LIVE ฟรี 30 นาที',
  '3. ไม่จำกัดเวลา LIVE',
  '4. รองรับสินค้า',
  '5. ระบบดูดคอมเมนท์เข้า INBOX',
  '6. ระบบ AI แชทบอทช่วยขายสินค้า',
  '7. ระบบ AI ตรวจจับสลิปโอนเงิน',
  '8. ระบบ AI ตรวจจับที่อยู่',
  '9. ระบบสรุปคำสั่งซื้อในแชท',
  '10. ระบบรวมคำสั่งซื้อจากทุก LIVE',
  '11. ระบบจัดการสต๊อกสินค้า',
  '12. ฟีเจอร์บรอดแคสต์กระตุ้นยอดขาย',
]

export const PriceTable = () => {
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <Container>
      <div className="has-text-centered">
        <p className="title title-block is-size-4 is-size-3-mobile is-marginless">
          อัตราค่าบริการแบบประหยัด เหมาะสำหรับธุรกิจเริ่มต้น
        </p>
      </div>

      <section className="hero is-hidden-touch">
        <div className="hero-body">
          <div className="container">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div>
                <CardContainer color="#1871B0" width="320px">
                  <CardHeader color="#3166BC">
                    <TextTitleHeader>PACKAGES</TextTitleHeader>
                  </CardHeader>
                  <CardBody style={{ textAlign: 'left' }}>
                    {dataKey.map((i) => (
                      <TextTitleBody key={i}>{i}</TextTitleBody>
                    ))}
                  </CardBody>
                </CardContainer>
              </div>

              {packages.map((i) => (
                <div key={i.package}>
                  <CardContainer color={i.containerColor}>
                    <CardHeader bg={i.headerBg} color={i.headerColor}>
                      <TextTitleHeader>{i.package}</TextTitleHeader>
                      <TextPriceHeader>{i.price}</TextPriceHeader>
                      <TextPromotionHeader>{i.promotion}</TextPromotionHeader>
                    </CardHeader>
                    <CardBody bg={i.bodyBg}>
                      {i.value.map((j, index) => (
                        <TextBody key={index}>{j}</TextBody>
                      ))}
                    </CardBody>
                  </CardContainer>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="hero is-hidden-desktop">
        <div className="hero-body">
          <div className="container">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {[packages[activeIndex]].map((i) => (
                <div key={i.package}>
                  <CardContainer color={i.containerColor} noMargin>
                    <CardHeader bg={i.headerBg} color={i.headerColor}>
                      <TextTitleHeader>{i.package}</TextTitleHeader>
                      <TextPriceHeader>{i.price}</TextPriceHeader>
                      <TextPromotionHeader>{i.promotion}</TextPromotionHeader>
                    </CardHeader>
                    <CardBody bg={i.bodyBg}>
                      {i.value.map((j, index) => (
                        <>
                          <MobileDescBody>{dataKey[index]}</MobileDescBody>
                          <MobileTextBody key={index}>{j}</MobileTextBody>
                        </>
                      ))}
                    </CardBody>
                  </CardContainer>
                </div>
              ))}
              {packages
                .filter((i) => !(i.id === activeIndex))
                .map((j, index) => (
                  <MobileTab
                    bg={j.headerBg}
                    color={j.headerColor}
                    key={j.id}
                    z={8 - index}
                    onClick={() => {
                      setActiveIndex(j.id)
                    }}
                  >
                    <MobileTabTitle>{j.package}</MobileTabTitle>
                  </MobileTab>
                ))}
            </div>
          </div>
        </div>
      </section>
    </Container>
  )
}

export default PriceTable
